body,
html {
  overflow-x: hidden;
  margin: 0;
}
.dashed-btn,
nav button {
  text-transform: capitalize !important;
}
.bottom-list-card h4,
.reservation-tab label {
  font-family: rotis-sans-serif-bold;
}
.ant-dropdown a,
.dashed-btn,
.footer-list li a,
.menu-content span a,
.res-btn,
.sub-header li a,
ul.side-menu-list li a {
  text-decoration: none;
}
.MuiFormControlLabel-label,
.ex-title {
  font-weight: 700 !important;
}
.cruise-charter .day-div ul,
.modal_list ul,
.question-answer-div-main .faq-div ul,
.question-answer-div-main .inclusions ul,
.side-menus-div ul,
.vskl-gallery-modal.gallery-one .gallery-div-massonary-min,
.vskl-taste .day-trips-section,
html {
  padding: 0;
}
body {
  padding: 0 !important;
  min-height: 100vh;
}
.banner_container.MuiContainer-root,
nav .MuiContainer-root {
  max-width: 1500px !important;
  padding: 0 24px;
}
.MuiContainer-root {
  max-width: 1380px !important;
  padding: 0 24px;
}
.MuiMasonry-root {
  max-width: 1350px !important;
  padding-left: 24px;
  padding-right: 24px;
}
.experience-slider,
.number-slider.vskl-number-slide .btn-slider-div {
  margin-right: 0;
}
.react-multi-carousel-item,
.react-multi-carousel-track {
  transform-style: inherit !important;
  will-change: inherit !important;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.ant-dropdown,
.ant-dropdown-menu,
.brown-back,
.img-crop-modal .ant-btn-primary,
.img-crop-modal .ant-btn-primary:hover,
.swal-button,
.swal-button:hover {
  background-color: #403836 !important;
}
.reservation-tab-main,
nav button {
  background-color: #050505;
}
.btn-slider-div button.slick-arrow {
  top: 30%;
}
.btn-slider-div .slick-slider:hover button.slick-arrow {
  display: block !important;
  top: 30%;
}
.reservation-tab {
  padding: 20px 0;
  color: #d9ccc5;
}
.reservation-tab-main {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
}
.reservation-tab-main .dashed-btn {
  width: auto;
  font-size: 28px !important;
  padding: 20px !important;
}
.exprice-slider h6,
.reservation-tab .date-piker-section label {
  font-size: 12px;
}
.reservation-tab label {
  font-weight: 400;
  font-size: 16px;
  color: #d9ccc5;
  text-transform: uppercase;
}
.reservation-tab .MuiInputBase-formControl:hover input,
.reservation-tab input {
  color: #d9ccc5;
  border: none;
  border-bottom: 1px dashed #d9ccc5;
}
.hedding-color,
.moterbike-day,
.pack_div_new label,
.page-description-data h3,
.page-description-div .page-description-data p,
.reservation-tab .MuiSelect-nativeInput,
.slider-content h3,
.text-description-div h2 {
  color: #d9ccc5;
}
.reservation-tab .date-piker-section input.rmdp-input,
.rmdp-input:focus {
  border: none;
  padding: 4px 0 5px;
  height: 30px;
  background-color: #050505;
  border-radius: 0;
  border-bottom: 1px dashed #d9ccc5;
  box-shadow: none;
}
.form-control:focus,
nav {
  box-shadow: none !important;
}
.reservation-tab .MuiFormControl-root label {
  font-size: 12px;
  font-weight: 400;
}
.reservation-tab select {
  padding: 0;
  height: 30px;
  background-color: transparent;
  color: #d9ccc5;
  border: none;
  border-bottom: 1px dashed #d9ccc5;
}
.reservation-tab .rmdp-container {
  display: block !important;
}
.bottom-cards-div,
.cursor-btn,
.normal-card-div,
.slider__item {
  cursor: pointer;
}
.main-white-header {
  background-color: #f8f4f4 !important;
}
.main-white-header .reserve-head {
  color: #050505 !important;
}
.sub-header button {
  font-size: 16px;
  background-color: transparent !important;
  border: none !important;
  padding: 5px !important;
}
.stay-home .sub-header button,
h6.name_hotel {
  font-size: 11px;
}
.banner-slider-card,
.border-btm,
.border-dashed-ex,
.default_not_used,
.exclusive-offer-page .taste-section .normal-card-section,
.getting_text,
.map-border-div.border-dashed,
.map-section-div,
.menu-content.stay-list a,
.other-sect,
.page-description-div .heading-div,
.reserve-head,
.room-type-all .exclusiveboox,
.room-type-two .exclusiveboox,
.side-menu-list li a,
.sub-header ul li button,
.sub-header-div,
.tab-button .slider-count-text span.active,
.tamarind-spring .celebration-internal-div,
.travel-page .motor-bike-section .normal-card-section,
.vskl-gallery-modal.gallery-one,
.vskl-taste .day-trips-section .day-trip-section-bordered {
  position: relative;
}
.sub-header ul li:first-child button.active::before {
  content: "";
  background-image: url(/src/assets/images/select-line.png);
  height: 5px;
  width: 75%;
  position: absolute;
  bottom: 4px;
}
span.read_more_icon svg {
  font-size: 11px;
  height: 10px;
  width: 10px;
}
span.read_more_icon.showingDown svg {
  transform: rotate(90deg);
  font-size: 11px;
}
span.read_more_icon.showingUp svg {
  transform: rotate(-90deg);
  font-size: 11px;
}
.pt-70 {
  padding-top: 70px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.exclusive-slider .slick-slide {
  padding: 0 12px;
}
.main-bg {
  background-color: #403836 !important;
  padding-top: 100px;
}
.MuiBackdrop-root,
.dashed-btn,
button:hover {
  background-color: transparent !important;
}
.about-us .cards-big img,
.about-us img,
.banner-div img,
.bottom-cards-div img,
.card-design-big-one img,
.home-page .banner-div img,
.normal-card-section .card-design img,
.ticket_list_card,
main {
  width: 100%;
}
.dashed-btn {
  width: 220px;
  height: 55px;
  background-image: url(../src/assets/images/btn.png);
  border: none !important;
  background-size: 100% 100%;
  color: #d9ccc5 !important;
  padding: 7px 35px !important;
  border-radius: 0 !important;
  font-size: 32px !important;
  font-family: inherit !important;
}
.border-btm::before,
.celebrate-singapore-page .text-description-div::before,
.event-card::after,
.exclusive-offer-page .stay-section .normal-card-section::before,
.home-page .home-tab-section:before,
.page-description-div .heading-div:before,
.stay-home.stay-page .motor-bike-section .normal-card-section::before,
.sub-header-div::after,
.travel-page .motor-bike-section .normal-card-section::before,
.vskl-ex-offer .exclusive-newnew::before {
  position: absolute;
  content: "";
  background-image: url(../src/assets/images/border-btm.png);
  left: 0;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.navbar-main {
  padding: 35px 0 !important;
  transition: 0.5s ease-in !important;
}
.headerSmall {
  padding: 0 !important;
  transition: 3s ease-in;
  height: auto !important;
}
nav button svg {
  color: #fff;
  width: 20px;
}
.text-video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 43% 0;
}
.text-video h4 {
  margin-left: 75px;
  font-size: 30px;
}
.form-control:focus {
  border-color: #707070 !important;
}
.read-more-content {
  overflow: hidden;
  transition: max-height 0.3s;
}
.read-more-content.expanded {
  max-height: none;
}
.read-more-button {
  background: 0 0;
  border: none;
  cursor: pointer;
  color: #00f;
}
.celebration_cards_main .normal-card-div:nth-child(2),
.celebration_cards_main .normal-card-div:nth-child(3),
.exclusive-offer-page .stay-section .slick-slide:nth-child(2n) .normal-card-div,
.exclusive-offer-page
  .taste-section
  .slick-slide:nth-child(odd)
  .normal-card-div,
.exclusive-offer-page
  .travel-section
  .slick-slide:nth-child(odd)
  .normal-card-div,
.home-page .slick-slide:nth-child(3),
.home-page .slick-slide:nth-child(6),
.mt-80,
.other-div .slick-slide:nth-child(3) .bottom-cards-div,
.taste-home .slick-slide:nth-child(3),
.taste-home .slick-slide:nth-child(6),
.travel-category.travel
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(4),
.vskl-ex-offer .react-multi-carousel-item:nth-child(11),
.vskl-ex-offer .react-multi-carousel-item:nth-child(4),
.vskl-ex-offer .react-multi-carousel-item:nth-child(7),
.vskl-gallery .react-multi-carousel-item:nth-child(4),
.vskl-gallery .react-multi-carousel-item:nth-child(6),
.vskl-gallery .react-multi-carousel-item:nth-child(8),
.vskl-taste .react-multi-carousel-item:nth-child(11),
.vskl-taste .react-multi-carousel-item:nth-child(4),
.vskl-taste .react-multi-carousel-item:nth-child(7) {
  margin-top: 80px;
}
.home-tab-section.text-center {
  margin-top: 5px;
}
.home-page .card-design-big-one img,
.vskl-ex-offer .exclusive-newnew img {
  height: auto;
}
.home-page .page-description-div .page-description-data p,
.taste-page .page-description-div .page-description-data p {
  font-size: 37px;
  text-align: center;
  color: #d9ccc5;
}
.about-us.taste-page .page-description-div .page-description-data p {
  font-size: 37px;
}
.zoom-banner {
  transform: scale(1.2);
  transition: 0.8s;
  height: 755px;
  margin-bottom: 110px;
  animation: 0.2s ease-out zooming;
}
#keys h1,
.one-main-text {
  transform: translate(-50%, -50%);
}
@keyframes zooming {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.3);
  }
}
.home-tab-section {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 165px;
}
.home-page .home-tab-section button {
  color: #d9ccc5;
  font-size: 36px;
  text-transform: capitalize;
  font-family: RotisSerif !important;
}
.card-design-big-one {
  margin-top: 195px;
}
.home-page .banner-div {
  padding: 0 22px 20px;
  background-color: #403836;
  color: #d9ccc5;
}
.home-page .page-description-div {
  padding: 11px 22px 20px;
  background-color: #403836;
  color: #d9ccc5;
}
.room-type-all .day-trips-section {
  position: relative;
  padding-top: 200px;
}
.book-offer-div::before,
.celebrate-singapore-page .tab-button::before,
.celebration-and-event-page
  .celebration_cards_main
  .normal-card-section::before,
.cruise-page .deck-main.normal-card-section::before,
.cruise-yatch .on-board-life:after,
.map-border-div.border-dashed::before,
.room-type-all .day-trips-section::before,
.room-type-all .exclusiveboox::before,
.room-type-two .exclusiveboox::before,
.vskl-taste .day-trips-section .day-trip-section-bordered::before,
.wellness-vskl .day-trips-section .day-trip-section-bordered::before {
  position: absolute;
  content: "";
  background-image: url(../src/assets/images/border-btm.png);
  left: 0;
  height: 2px;
  width: 100%;
  top: 0;
}
.banner-slider-card.btn-slider-div .react-multiple-carousel__arrow--left,
.banner-slider-card.btn-slider-div .react-multiple-carousel__arrow--right,
.gallery-one .btn-slider-div .slick-slider:hover button.slick-arrow,
.gallery-one .btn-slider-div button.slick-arrow {
  top: 45%;
}
.room-type-all .card-description h4 {
  font-size: 35px;
}
.room-type-page .page-description-div {
  padding: 40px 0;
  padding-bottom: 100px !important;
  background-color: #403836;
}
.room-type-page .page-description-div .page-description-data p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 22px;
}
.home-page .react-parallax {
  padding: 0 22px 20px;
  transition: 2s;
  background-color: #403836;
}
.react-parallax-bgimage {
  height: 780px !important;
  padding: 0 22px 20px;
}
.one-main-text {
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 24px;
}
.room-detail-section {
  background-color: #403836;
  padding: 40px 0;
}
.page-description-div {
  padding-bottom: 230px !important;
}
.page-description-div .heading-div h3 {
  margin: 38px 0 55px;
  font-size: 35px;
}
.big-cards-div .card-design-big-two img {
  margin-bottom: 42px;
  width: 100%;
}
.slider-container {
  padding: 0 50px;
  margin-bottom: 40px;
}
.banner-div p,
.cards.heading,
.celebration-enquiry .radio-input label,
.celebration-internal-div .cards.heading h4,
.ex-homepage,
.exprice-slider,
.main-bg .sub-header .MuiSelect-select,
.room-features h6,
.room-type-two h3,
.room-type-two h4,
.room-type-two h5,
.room-type-two h6,
.room-type-two p,
.slider-content h5,
.slider-content p,
.slider-content span,
.text-description-div p {
  color: #fff;
}
.slider-content ul {
  padding-left: 15px;
}
.slider-content li {
  list-style: disc;
  color: #fff;
}
.page-description-div .heading-div h5 {
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  font-family: RotisSansSerif, Rotis-Sans-Serif-Bold-65;
}
.cruise-page .room-type-page .page-description-data p,
.exprice-slider p,
.job-apply .job-card-flex p.post-name,
.moterbike-day .listprice p,
.page-description-div .heading-div span,
.show_menu_data ul.side-menu-list li a,
.stay-page .page-description-div .page-description-data p,
.vskl-taste .banner-div p {
  font-size: 16px;
}
.page-description-div .heading-div span a {
  color: #fff;
  text-decoration: underline;
  font-family: RotisSansSerif, Rotis-Sans-Serif-Bold-65;
}
.big-cards-div img {
  margin-bottom: 30px;
  width: 100%;
}
.center-big-card-list:after {
  left: 6%;
  content: "";
  height: 1px;
  width: 85%;
  background-image: url(../src/assets/images/border-btm.png);
  position: absolute;
  bottom: -0.2px;
}
.home-page .center-big-card-list:after {
  left: 4%;
  content: none;
  height: 2px;
  width: 92%;
}
.center-big-card-list {
  min-height: 600px;
  padding: 80px 0;
  position: relative;
  background-size: 100% 100%;
  background: linear-gradient(180deg, #403836 0, #403836 72%, #625754 100%)
    no-repeat padding-box;
}
.card-design-big-one .cards.heading {
  margin-left: 30px;
}
.home-page .cards.heading {
  margin-top: 30px;
}
.big-cards-div.retreats_div {
  margin: 0 80px 70px 0;
}
.card-design-big-two,
.stay-page
  .motor-bike-section
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: -180px;
}
.card-design img {
  min-height: 468px;
  width: 100%;
  margin: 0 0 65px;
}
.offer_slider_page .card-design img {
  object-fit: contain;
}
.section-head {
  color: #d9ccc5;
  margin: 40px 0;
}
.cards.heading h6 {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-family: RotisSerif;
  margin-bottom: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.cards.heading h4 {
  font-size: 28px;
  color: #d9ccc5;
  margin-bottom: 8px;
}
.cards.heading p.term_condition_heading{
    font-size: 28px;
    color: #d9ccc5!important;
    
}
.celebration_cards_main .event-card .cards.heading p {
  overflow: visible;
  display: block;
}
.cards.heading p {
  font-size: 16px;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
}
.cards.heading a,
.cruise-home.cruise-page .deck-plan p {
  font-size: 16px;
  color: #fff;
}
.bottom-card-list {
  background-color: #403836;
  padding: 80px 0;
}
.sub-header {
  margin-top: 20px;
  background-color: #403836;
  padding: 20px 0 65px;
}
.sub-header .MuiSelect-select {
  font-size: 32px;
  font-family: RotisSerif;
  padding-right: 10px !important;
}
.sub-header
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
ul.MuiList-root {
  background-color: #d9ccc5;
}
ul.MuiList-root li {
  font-family: RotisSerif;
  color: #403836;
}
ul.MuiList-root .Mui-selected,
ul.MuiList-root li:hover {
  background-color: #403836;
  color: #fff;
}
.footer-prev .h-64 {
  height: 35px;
}
.footer-main::after,
.footer-main::before {
  left: 57px;
  content: "";
  height: 2px;
  position: absolute;
  background-image: url(../src/assets/images/border-btm.png);
}
.footer-main ul.MuiList-root,
.footer-main ul.MuiList-root .Mui-selected,
.footer-main ul.MuiList-root li:hover {
  background-color: transparent;
}
.sub-header fieldset.MuiOutlinedInput-notchedOutline {
  border-width: 0;
}
.ant-dropdown-menu,
.ant-tooltip,
.birthday-party,
.content-none .cards.heading,
.detail-input:first-child .delete_icon_btn,
.left_tabs_btn .MuiTabs-indicator,
.menu-btn-div span,
.place-card.place-card-large,
.reserve-head span,
.sub-header-select svg,
.vskl-ex-offer.vskl-taste .exclusive-card-data .white-input-small {
  display: none;
}
.sub-header h3 {
  color: #fff;
  font-size: 32px;
}
.footer-prev button,
.footer-prev input {
  font-size: 0.7285714286rem;
  text-align: center;
  background-size: 100% 100%;
  color: #050505;
}
.sub-header li a {
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
}
.social-link-footer li,
.sub-header ul li {
  display: inline-block;
}
.sub-header ul {
  margin-top: 0;
  margin-bottom: 4px;
}
.logo-header {
  width: 188px;
}
.footer-last {
  background-color: #a6958f;
  height: 30px;
  width: 100%;
}
.footer-main::before {
  width: 93%;
  top: 0;
}
.footer-main::after {
  width: 97%;
  bottom: -0.2px;
}
.footer-main {
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 15px;
  padding: 0 15px 70px;
}
.footer-prev input {
  border: none;
  border-radius: 0;
  background-repeat: no-repeat;
}
.footer-prev .footer_btn_submit,
.footer-prev input.footer_input_one {
  background-image: url(../src/assets/images/small-border.png);
}
.reserve-head::after,
.side-menu-list li:hover a::after,
.side-menus-div .stay-list span:hover::after {
  background-image: url(/src/assets/images/border-pic.svg);
  position: absolute;
  left: 0;
  content: "";
  height: 2px;
}
.footer-prev input.footer_input_two {
  background-image: url(../src/assets/images/big-border.png);
  width: 100%;
}
.footer-prev button {
  border: none;
  border-radius: 0;
  text-transform: capitalize;
  display: block;
  width: 100%;
  font-family: inherit;
}
.dropdown_menu_new h6,
.left_tabs_btn button {
  font-family: RotisSansSerif, Rotis-Sans-Serif-Bold-65;
}
.footer-prev input::placeholder {
  color: grey;
}
.footer-prev {
  padding: 63px 0 44px;
}
.heading-review-div h4,
.social-link-footer p {
  font-size: 20px;
}
.footer-main .MuiTypography-h6 {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 28px;
}
.footer-list li {
  padding: 2px 0;
}
.footer-list li a {
  color: #050505;
  line-height: 20px;
}
.footer-sub {
  padding: 32px 0 74px;
  align-items: center;
}
.footer-cont {
  margin-right: 20px;
}
.footer-main a,
.footer-main a:hover {
  color: #050505;
  text-decoration: none;
}
.footer-sub span {
  color: #050505;
  font-size: 16px;
  text-align: right;
}
.menu-content span a,
.side-menus-div label,
ul.side-menu-list li a {
  font-size: 20px;
  color: #d9ccc5;
}
.social-link-footer {
  display: flex;
  align-items: center;
}
.ex-homepage .slick-slide,
.social-link-footer li a {
  padding: 0 10px;
}
.testimonial-type-card img {
  margin-right: -30px;
}
.MuiDrawer-modal .MuiDrawer-paper {
  box-sizing: border-box;
  width: 100% !important;
  background-color: #050505 !important;
  transition: none !important;
}
.MuiDrawer-paper {
  animation: 5s MuiDrawer-paper;
  -webkit-animation: 5s MuiDrawer-paper;
  -moz-animation: 5s MuiDrawer-paper;
  -o-animation: 5s MuiDrawer-paper;
  -ms-animation: MuiDrawer-paper ease 5s;
}
@keyframes MuiDrawer-paper {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes MuiDrawer-paper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes MuiDrawer-paper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes MuiDrawer-paper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes MuiDrawer-paper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.nav-menu-section {
  padding: 40px 0;
}
.side-menus-div {
  margin: 40px 0 0;
  text-align: left;
}
.multi-images img,
.side-menus-div label {
  margin-bottom: 10px;
}
.side-menus-div p {
  text-transform: uppercase;
}
ul.side-menu-list li {
  padding: 3px 0 7px;
}
.menu-content {
  margin-right: 50px;
}
.menu-content span a {
  line-height: 36px;
}
.side-menus-div .small-title {
  color: #d9ccc5;
  letter-spacing: 2px;
  font-size: 11px;
  margin: 15px 0 5px;
}
.reserve-head::after {
  width: 100%;
  bottom: 12px;
}
.side-menu-list li:hover a::after {
  width: 100%;
  bottom: -3px;
  transform: rotate(180deg);
}
.side-menus-div .stay-list span {
  position: relative;
  line-height: 33px;
  color: #d9ccc5;
  cursor: pointer;
}
.celebration-enquiry .rmdp-calendar,
.motor-bike-one .rmdp-calendar,
.motor-bike-one input.rmdp-input {
  color: #73625c;
  background-color: #d9ccc5;
}
.side-menus-div .stay-list span:hover::after {
  width: 80px;
  top: 20px;
  transform: rotate(180deg);
}
.motor-bike-one .rmdp-wrapper {
  background-color: #d9ccc5;
  height: 100%;
}
.motor-bike-one input.rmdp-input {
  border-radius: 0;
  width: 100%;
  padding: 6px 12px;
}
.travel-page-home .motor-bike-section {
  background-image: url(/src/assets/images/motorgrey.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 600px;
}
.travel-page-home .motor-bike-section .normal-card-section::before {
  width: 100%;
  position: absolute;
  content: "";
  height: 2px;
  left: 0;
  background-image: url(/src/assets/images/border-btm.png);
  bottom: 0;
}
.celebrate-singapore-page .experience-div-section,
.cruise-charter .experience-div-section,
.cruise-page .experience-div-section,
.cruise-yatch .experience-div-section,
.experience-section-page .experience-div-section,
.stay-home .experience-div-section,
.travel-motor-bike .experience-div-section,
.travel-page-home .experience-div-section {
  padding: 85px 0 250px;
}
.card-description,
.celebration-and-event-page .experience-div-section .section-head h3,
.celebration-and-event-page .normal-card-div,
.cruise-yatch .experience-div-section .section-head h3,
.job-apply .page-description h3,
.mb-100,
.stay-home.stay-page .experience-div-section .section-head h3,
.tamarind-celebration .event-card-small .cards.heading h4,
.travel-page-home .experience-div-section .section-head h3 {
  margin-bottom: 100px;
}
.about-us .cards.heading,
.book-offer-div .section-head,
.exclusive-newnew .cards.heading p,
.experience-div-section .testimonial-type-card .cards.heading,
.pack_div_new p,
.tamarind-spring .scroll-slider-div .normal-card-section .normal-card-div,
.tamarind-spring
  .taste-center-card
  .normal-card-section
  .normal-card-div:first-child,
.vskl-home .number-slider .cards.heading {
  margin-bottom: 0;
}
.travel-page-home .day-trips-section {
  padding: 125px 0 100px;
}
.travel-page-home .sub-header {
  padding: 0 0 30px;
}
.travel-page-home .page-description-div {
  padding: 155px 0 100px !important;
}
.ant-modal.css-dev-only-do-not-override-1n7nwfa,
.exclusive-slider .slick-track,
.single_image_upload .ant-upload-wrapper {
  width: 100% !important;
}
.exclusive-slider .slick-initialized .slick-slide {
  width: 400px !important;
}
.travel-category.travel
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: -590px;
}
.travel-category.travel
  .motor-bike-section
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: -520px;
}
.travel-category
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: -475px;
}
.travel-category
  .motor-bike-section
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: -372px;
}
.motor-bike-section .normal-card-section .normal-card-div:nth-child(4),
.motor-bike-section .normal-card-section .normal-card-div:nth-child(5) {
  margin-top: 75px;
}
.taste-page .page-description-div,
.travel-category .page-description-div {
  padding: 70px 0 525px !important;
}
.contact-us-page .address-div-pic,
.travel-category.travel .motor-bike-section {
  padding: 80px 0 0;
}
.travel-category .motor-bike-section {
  background-image: url(../src/assets/images/motorgrey.png);
  min-height: 600px;
  padding: 80px 0;
  position: relative;
  background-size: cover;
  background-color: #403836;
}
.stay-page .motor-bike-section,
.taste-center-card {
  background-size: 100% 100%;
  background-color: #403836;
}
.newpadding,
.travel-page .banner-div {
  padding: 0 22px 20px;
}
.normal-card-section {
  position: relative;
  padding: 50px 0 70px;
}
.experience-div-section {
  padding: 70px 0 120px;
  background-color: #d9ccc5;
}
.experience-div-section .section-head h3 {
  margin-bottom: 70px;
  font-size: 45px;
}
.banner-div h1,
.experience-div-section .cards.heading a,
.experience-div-section .cards.heading h4,
.experience-div-section .cards.heading p,
.experience-div-section .section-head,
.experience-section-page .cards.heading a,
.experience-section-page .cards.heading h4,
.experience-section-page .cards.heading h6,
.experience-section-page .cards.heading p,
.experience-section-page .sub-header li button,
.experience-section-page .sub-header li button:hover,
.exprice-slider.ex-home a {
  color: #050505;
}
.motor-bike-section .normal-card-section .normal-card-div:first-child {
  margin-top: 60px;
}
.page-description-div .heading-div {
  padding: 0 0 28px;
  margin-bottom: 60px;
}
.home-page .page-description-div .heading-div {
  margin-bottom: 40px;
  margin-top: 37px;
  padding-bottom: 1px;
}
.travel-motor-bike .normal-card-section {
  padding: 130px 0 70px;
}
.travel-motor-bike
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: 105px;
}
.travel-motor-bike
  .motor-bike-section
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: 175px;
}
.travel-motor-bike .day-trips-section {
  padding: 140px 0 100px;
}
.taste-home.taste-page .page-description-div {
  padding: 175px 0 525px !important;
}
.taste-home
  .taste-center-card
  .normal-card-section
  .normal-card-div:first-child {
  margin-bottom: -35px;
}
.taste-home
  .taste-center-card
  .normal-card-section
  .normal-card-div:nth-child(5) {
  margin-top: -85px;
}
.taste-home .taste-center-card .normal-card-section {
  padding: 50px 0 60px;
}
.taste-home
  .taste-center-card
  .normal-card-section
  .normal-card-div:nth-child(6) {
  margin-top: -15px;
}
.banner-div {
  padding: 0 0 20px;
}
.taste-home .taste-center-card::before {
  left: 125px;
  width: 85%;
}
.taste-center-card {
  position: relative;
  background-image: url(../src/assets/images/tastegrey.png);
  background-repeat: no-repeat;
}
.taste-home .day-trips-section {
  padding: 145px 0 40px;
}
.taste-home .experience-div-section {
  padding: 60px 0 150px !important;
}
.taste-home .experience-div-section .section-head h3 {
  margin-bottom: 106px;
}
.taste-center-card .normal-card-section .normal-card-div:nth-child(2) {
  margin-top: -433px;
}
.taste-center-card .normal-card-section .normal-card-div:first-child {
  margin-top: -280px;
  margin-bottom: 106px;
}
.taste-center-card .normal-card-section .normal-card-div:nth-child(4),
.taste-center-card .normal-card-section .normal-card-div:nth-child(6) {
  margin-top: 115px;
}
.celebration_cards_main .event-card:last-child,
.taste-page .normal-card-section {
  border: none;
}
.day-trips-section {
  padding: 85px 0 100px;
  background-color: #403836;
}
.experience-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.experience-slider .slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 10px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: #000;
  border-bottom: 5px solid #d6d1d1;
}
.banner-slider-card .slick-prev {
  right: auto;
  left: 43%;
  bottom: -37px;
  top: auto;
  z-index: 99;
}
.banner-slider-card .slick-next {
  left: auto;
  right: 45%;
  bottom: -42px;
  top: auto;
  z-index: 99;
}
.banner-slider-card .slider-count-text {
  margin-top: 15px;
  color: #d9ccc5;
  font-size: 24px;
}
.deck-plan-slider .banner-slider-card img {
  height: 526px;
}
.banner-slider-card img {
  height: 757px;
  object-fit: cover;
  width: 100%;
}
.day-trips-section .slick-slide:nth-child(2n),
.img-crop-modal .img-crop-control:first-of-type,
.stay-home .page-description-div .page-description-data p,
.tamarind-spring .react-multi-carousel-item:nth-child(5),
.tamarind-spring .react-multi-carousel-item:nth-child(6),
.tamarind-spring .react-multi-carousel-item:nth-child(8),
.tamarind-spring
  .taste-center-card
  .normal-card-section
  .normal-card-div:first-child,
.tamarind-spring
  .taste-center-card
  .normal-card-section
  .normal-card-div:nth-child(2),
.vskl-home
  .number-slider.vskl-number-slide
  .react-multi-carousel-item:nth-child(2n) {
  margin-top: 0;
}
.stay-home.stay-page .page-description-div {
  padding-bottom: 280px !important;
}
.stay-home.stay-page
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: -290px;
}
.stay-home.stay-page
  .motor-bike-section
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: -170px;
}
.stay-home.stay-page .motor-bike-section .normal-card-section {
  position: relative;
  padding: 50px 0 57px;
}
.stay-home .day-trips-section {
  padding: 150px 0 100px;
}
.stay-title {
  font-size: 50px;
}
.stay-page .slick-dots {
  position: absolute;
  bottom: 50px;
  left: 65px;
}
.stay-page .room-type-page .page-description-div {
  padding: 40px 0;
  padding-bottom: 170px !important;
}
.stay-page
  .motor-bike-section
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: -260px;
}
.stay-page .motor-bike-section {
  background-image: url(../src/assets/images/stay-grey.png);
  min-height: 600px;
  padding: 80px 0 0;
  position: relative;
}
.btn-slider-div .slick-next:hover,
.btn-slider-div .slick-prev:hover,
.react-multi-carousel-item.react-multi-carousel-item--active {
  opacity: 1;
}
.vskl-home .map-sidebar .banner-div {
  padding: 10px 0 20px;
}
.vskl-gallery-modal.gallery-one .close-slider {
  font-size: 30px;
  position: absolute;
  color: #d9ccc5;
  z-index: 9;
  right: 10px;
  top: 0;
}
.vskl-gallery-modal.gallery-one .close-slider:hover {
  color: red;
}
.vskl-home .vskl-number-slide .react-multi-carousel-list {
  padding-bottom: 140px;
}
.vskl-home .slider-count-text {
  margin-left: 13%;
  width: 200px;
  text-align: center;
  margin-top: -45px;
}
.vskl-ex-offer .other-div {
  padding-top: 245px;
  padding-bottom: 200px;
}
.card-description a,
.detail-section p,
.tamarind-spring .page-description-data.tamarind-spring-text p.small-text,
.tamarind-spring .slider-text-data p,
a.all-show-btn,
a.all-show-btn:hover {
  color: #fff;
  font-size: 16px;
}
.vskl-ex-offer .exclusive-newnew {
  position: relative;
  padding-top: 75px;
}
.vskl-ex-offer .exclusive-card-data ul {
  padding-left: 18px;
}
.vskl-home .page-description-div .page-description-data p {
  line-height: 30px;
}
.geting-text {
  font-size: 45px;
  color: #d9ccc5;
}
.page-description-div .page-description-data p {
  font-size: 16px;
  text-align: center;
}
.white-btn,
.white-btn:hover {
  background-color: #fff !important;
  background-image: url(../src/assets/images/btn.png);
  background-size: 100% 100%;
  color: #050505 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding: 9px 70px !important;
  border: none !important;
  border-radius: 2px !important;
  font-family: inherit !important;
}
.book-offer-div {
  position: relative;
  padding: 90px 0;
}
.book-offer-div h3,
.font-45 {
  font-size: 45px;
}
.map-section-div .cards.heading {
  text-align: left;
  width: 70%;
}
.map-border-div {
  padding: 148px 0 25px;
}
.vskl-home .page-description-div {
  padding: 153px 0 70px !important;
}
.vskl-home .number-slider .heading {
  width: 70%;
}
.vskl-home .number-slider .slick-prev {
  right: auto;
  left: 38%;
  bottom: -95px;
  top: auto;
  z-index: 99;
}
.vskl-home .number-slider .slick-next {
  left: auto;
  right: 40%;
  bottom: -95px;
  top: auto;
  z-index: 99;
}
.vskl-home .number-slider .slick-prev:before {
  content: "";
  background-image: url(../src/assets/images/arrow.svg);
  height: 24px;
  width: 23px;
  position: absolute;
  transform: rotate(180deg);
  background-repeat: no-repeat;
}
.gallery-one
  .gallery-div-massonary-min
  .react-multiple-carousel__arrow--right::before,
.vskl-home .number-slider .slick-next:before {
  content: "";
  background-image: url(../src/assets/images/arrow.svg);
  height: 24px;
  width: 23px;
  position: absolute;
  background-repeat: no-repeat;
}
.vskl-home .day-trips-section {
  padding: 200px 0 70px;
}
.number-slider .border-btm {
  margin-top: 110px;
}
.vskl-home .number-slider {
  padding: 45px 0 25px;
  position: relative;
}
.stay-page .room-type-page,
.vskl-home .centerslider li:nth-child(2n) {
  padding-top: 0;
}
.slider-count-text {
  margin-top: 85px;
  color: #d9ccc5;
  font-size: 24px;
}
.slider-count-text p {
  margin-top: 39px;
  color: #d9ccc5;
  font-size: 24px;
}
.map-sidebar {
  position: absolute;
  height: 100%;
  width: 310px;
  top: 0;
  left: 0;
  background-color: #fff;
}
.vskl-home .rating-section {
  display: flex;
  font-size: 14px;
}
.cruise-yatch .birthday-party,
.listprice ul li,
.testimonial-type-card .cards.heading p {
  display: block;
}
.map-sidebar .star-div {
  margin-right: 5px;
}
.vskl-home .review-count {
  color: #3773e0;
  margin-right: 5px;
}
.home-tab-section button,
.vskl-home .input-sidebar-div {
  width: 300px;
}
.main-map-div {
  position: relative;
  height: 500px;
  margin-bottom: 104px;
  margin-top: 40px;
}
iframe {
  width: 100%;
  height: 500px;
}
.input-sidebar-div {
  display: flex;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 235px;
  height: 35px;
  z-index: 99;
  text-align: left;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
}
.room-type-page,
.topspace {
  background: #403836;
}
.map-sidebar,
.order-detail-page table td {
  padding: 15px;
}
.input-sidebar-div button,
.input-sidebar-div button:focus {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.input-sidebar-div input[type="text"] {
  border: none;
  width: 220px;
}
.map-sidebar .rating-section .star-div svg {
  color: #f1be42;
}
.heading-review-div {
  text-align: left;
}
.map-sidebar .rating-section .rating-count {
  color: #979a9d;
}
.map-sidebar-banner {
  width: 100%;
  height: 150px;
}
.input-sidebar-div img {
  width: 20px;
  height: 20px;
}
.celebration-internal-div .cards.heading {
  width: 55%;
}
.celebration-internal-div {
  padding: 70px 0;
  border-top: 1px dashed #fff;
  border-bottom: 1px dashed #fff;
}
.headerSmall .logo-header {
  width: 100px;
}
.topspace {
  padding-top: 70px;
}
.stay-page .page-description-div {
  margin-top: 165px;
}
#keys {
  position: relative;
  width: 100%;
  height: 928px;
  background-repeat: no-repeat;
  background-image: url(../src/assets/images/bannerhome.avif);
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
}
#keys h1 {
  position: absolute;
  top: 60%;
  left: 53%;
  font-size: 28px;
}
.zoom {
  overflow: hidden;
  padding-bottom: 55%;
}
.zoom img {
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 200%;
  width: 100%;
  transform: translateX(-50%);
}
.gallery-slider-height-div {
  padding: 1% 0;
  align-items: center;
  height: 100vh;
}
.vskl-gallery-modal.gallery-one .react-multi-carousel-item img {
  height: auto;
  width: auto;
  opacity: 0.41;
}
.vskl-gallery-modal.gallery-one
  .react-multi-carousel-item.react-multi-carousel-item--active
  img {
  height: auto;
  width: auto !important;
  opacity: 1;
  object-fit: contain;
}
.vskl-gallery-modal.gallery-one .slider-count-text {
  margin-top: -77px;
  padding-bottom: 20px;
}
.gallery-one.vskl-gallery .day-trips-section {
  position: relative;
  padding: 161px 0 0;
}
.gallery-one .gallery-div-masonary::after,
.gallery-one .gallery-div-masonary::before,
.gift-card-experience .exclusive-newnew:nth-child(2)::before,
.tamarind-spring .taste-center-card::before {
  content: none;
}
.gallery-one .day-trips-section::after,
.tamarind-spring .celebration-internal-div::before {
  content: "";
  position: absolute;
  background-image: url(../src/assets/images/border-btm.png);
  left: 0;
  height: 2px;
  width: 100%;
  top: 0;
}
.gallery-one .gallery-div-masonary {
  padding: 0 0 278px;
  position: inherit;
}
.gallery-one .slider-count-text {
  margin-top: -55px;
}
.gallery-one .gallery-div-massonary-min .react-multiple-carousel__arrow--right {
  bottom: 23px;
  right: 42%;
  background-color: transparent;
}
.gallery-one .gallery-div-massonary-min .react-multiple-carousel__arrow--left {
  bottom: 30px;
  left: 42%;
  background-color: transparent;
}
.gallery-div-masonary::after,
.gallery-div-masonary::before {
  background-image: url(../src/assets/images/border-btm.png);
  left: 0;
  width: 100%;
  height: 2px;
}
.vskl-gallery-modal.gallery-one
  .gallery-div-massonary-min
  .react-multiple-carousel__arrow--left {
  bottom: 50px;
  left: 42%;
  display: none !important;
  background-color: transparent;
}
.vskl-gallery-modal.gallery-one
  .gallery-div-massonary-min
  .react-multiple-carousel__arrow--right {
  bottom: 47px;
  right: 42%;
  background-color: transparent;
}
.vskl-gallery-modal.gallery-one .gallery-one ul.react-multi-carousel-track {
  align-items: center !important;
}
.gallery-one .react-multi-carousel-item.react-multi-carousel-item--active {
  transform: scale(1.3);
  margin: 0 auto;
  z-index: 9;
}
.gallery-one .react-multi-carousel-item.react-multi-carousel-item--active img {
  height: 700px;
  opacity: 1;
}
.gallery-one .react-multi-carousel-list {
  padding-bottom: 132px;
  padding-top: 55px;
}
.gallery-one .gallery-div-massonary-min {
  position: relative;
  padding: 217px 0 0;
}
.gallery-one .react-multi-carousel-item img {
  height: 532px;
  width: 100%;
  opacity: 0.41;
}
.gallery-one .react-multi-carousel-item .bottom-cards-div img {
  height: 100%;
  opacity: 1;
  width: 100%;
}
.vskl-gallery .day-trips-section {
  padding: 161px 0 190px;
  width: 100%;
}
.vskl-gallery .day-trips-section .slick-slide:nth-child(odd),
.vskl-home .react-multi-carousel-item:nth-child(2n) {
  margin-top: 50px;
}
.gallery-div-masonary {
  position: relative;
  padding: 217px 0 420px;
}
.gallery-div-masonary::before {
  position: absolute;
  content: none;
  top: 0;
}
.gallery-div-masonary::after {
  position: absolute;
  content: none;
  bottom: 0;
}
.gallery-card {
  background-color: #fff;
  border: 1px solid;
}
.bottom-dotted,
.text-description-div {
  border-bottom: 1px dashed #fff;
}
.btn-slider-div .react-multiple-carousel__arrow:before,
.celebration-enquiry .rmdp-week-day,
.digital-gift-card select,
.enquiry-input,
.experience-section-page .page-description p {
  color: #403836;
}
.celebration-enquiry .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #403836;
}
.celebration-enquiry input.rmdp-input {
  background-color: #d9ccc5;
  border-radius: 0;
  width: 100%;
  color: #73625c;
  padding: 6px 12px;
  height: 81px;
}
.tamarind-spring .celebration-internal-div::after,
.tamarind-spring .tabs-section::before {
  content: "";
  position: absolute;
  background-image: url(../src/assets/images/border-btm.png);
  left: 0;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.tamarind-spring .taste-center-card {
  background-image: none;
}
.tamarind-spring .page-description-data h3 {
  color: #d9ccc5;
  font-size: 45px;
  margin-bottom: 10px;
}
.tamarind-spring .page-description-data span {
  color: #d9ccc5;
  font-size: 16px;
}
.tamarind-spring .page-description-div {
  padding: 75px 0 0 !important;
}
.tamarind-spring .celebration-internal-div {
  border: none;
  padding: 105px 0 75px;
}
.tamarind-spring .day-trips-section {
  padding: 125px 0 100px;
  background-color: #403836;
}
.experience-section-page .exclusiveboox,
.tamarind-spring .tabs-section {
  padding: 80px 0;
  position: relative;
}
.tamarind-spring .slider-count-text {
  margin-top: -75px;
  color: #d9ccc5;
  font-size: 24px;
}
.pack_div_new h5 {
  margin-bottom: 0;
  font-size: 18px;
}
.pack_div_new p {
  font-size: 14px;
}
.venue_text_div {
  color: #d9ccc5;
  margin-bottom: 25px;
}
.tamarind-spring .slider-text-data {
  margin-top: 50px;
  text-align: center;
}
.tamarind-spring .normal-card-section {
  position: relative;
  padding: 50px 0 180px;
}
.tamarind-spring .tabs-section ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.tamarind-spring .tabs-section li {
  list-style: none;
  font-size: 20px;
  color: #d9ccc5;
}
.tamarind-spring .tabs-section li button {
  font-size: 20px !important;
}
.cruise-page .page-description-data button,
.mt-60 {
  margin-top: 60px !important;
}
.vskl-taste .banner-div h2 {
  margin-top: 110px;
  margin-bottom: 105px;
}
.vskl-taste .day-trip-section-bordered {
  padding: 160px 0 236px;
}
.vskl-taste .day-trips-section.restaurent-div .day-trip-section-bordered {
  padding-top: 105px;
  border-top: 0;
}
.cruise-yatch .on-board-life {
  padding: 200px 0;
  position: relative;
}
.cruise-yatch .on-board-life h2.geting-text {
  margin-bottom: 70px;
}
.cruise-home.cruise-page .page-description-data h2,
.question-answer-div-main li {
  margin-bottom: 15px;
  color: #f8f4f4;
}
.cruise-yatch .on-board-life .cards.heading {
  margin-bottom: 145px;
  width: 52%;
}
.cruise-yatch .text-description-div {
  padding: 70px 0 200px;
}
.cruise-yatch .banner-div {
  margin: 90px 0;
}
.cruise-yatch .banner-div h2 {
  margin-bottom: 92px;
}
.cruise-yatch.travel .normal-card-div::after,
.tamarind-celebration.celebrate-singapore-page
  .event-card-list
  .event-card:last-child::after {
  content: none;
  background-image: none;
}
.cruise-yatch .birthday-party p {
  font-size: 16px;
  margin-bottom: 100px;
}
.cruise-yatch .banner-slider-card .slick-prev {
  bottom: -148px;
  left: 44%;
}
.cruise-yatch .banner-slider-card .slick-next {
  bottom: -155px;
}
.cruise-charter .day-div h3 {
  font-size: 24px;
}
.cruise-charter .day-div,
.question-answer-div-main .faq-div {
  padding-left: 50px;
}
.cruise-charter .day-div li,
.modal_list li,
.question-answer-div-main .faq-div li {
  list-style: none;
}
.question-answer-div-main .inclusions li {
  list-style: none;
  margin: 0;
}
.question-answer-div-main h3 {
  font-size: 24px;
  color: #f8f4f4;
}
.cruise-page .page-description-data h2,
.experience-section-page .discover-div .cards.heading a,
.experience-section-page .discover-div .cards.heading h4,
.experience-section-page .discover-div .cards.heading h6,
.experience-section-page .discover-div .cards.heading p,
.question-answer-div-main p {
  color: #f8f4f4;
}
.question-answer-div-main p.question-set {
  font-size: 24px;
  color: #f8f4f4;
  margin: 0;
}
.moterbike-day h3,
.moterbike-day p,
.question-answer-div-main p.answer-set {
  font-size: 16px;
  color: #f8f4f4;
}
.cruise-charter .map-size-circle {
  padding: 120px 0 100px;
  margin-top: 75px;
}
.cruise-charter .pacakage-duration {
  color: #f8f4f4;
  font-size: 28px;
}
.cruise-home.cruise-page
  .taste-center-card
  .normal-card-section
  .normal-card-div:nth-child(2) {
  margin-top: 135px;
}
.cruise-home.cruise-page
  .taste-center-card
  .normal-card-section
  .normal-card-div:first-child {
  margin-top: 250px;
}
.cruise-page .taste-center-card {
  background-image: url(../src/assets/images/fish-bg.png);
  min-height: 600px;
}
.cruise-home.cruise-page .taste-center-card .normal-card-section {
  padding: 120px 0 0;
}
.--mt-130,
.cruise-page.cruise-home .deck-plan-slider {
  margin-top: 130px;
}
.cruise-page .section-head {
  color: #050505;
  margin-bottom: 105px;
}
.cruise-page .day-trips-section {
  padding: 133px 0 235px;
}
.cruise-yatch .slick-slide:nth-child(3) {
  margin-top: 30px !important;
}
.cruise-page .slick-slide:nth-child(odd) {
  margin: 0 !important;
}
.cruise-yatch .text-description-div {
  border: none;
  padding-top: 55px;
}
.cruise-yatch .text-description-div p {
  margin-bottom: 155px;
}
.cruise-yatch p.large-text {
  font-size: 45px;
  color: #d9ccc5;
  margin-bottom: 100px;
}
.cruise-yatch .exclusive-card {
  padding-top: 125px;
}
.cruise-yatch .normal-card-section {
  padding-bottom: 800px;
}
.content-substainability,
.cruise-page .page-description-data,
.mt-40 {
  margin-top: 40px;
}
.cruise-page .page-description-div {
  padding: 90px 0;
  padding-bottom: 200px !important;
}
.cruise-page .deck-plan-slider .slick-slide img {
  height: 690px;
}
.celebration-home .celebration_cards_main .normal-card-div:nth-child(6) {
  margin-top: 100px;
  margin-bottom: 0;
}
.celebration-home.celebration-and-event-page
  .celebration_cards_main
  .normal-card-section,
.contact-us-page .address-div-pic:nth-child(2),
.pb-200 {
  padding-bottom: 200px;
}
.celebrate-singapore-home.celebrate-singapore-page .text-description-div {
  padding: 130px 0 165px;
}
.tamarind-celebration .celebration_cards_main .event-card:last-child {
  padding-top: 160px;
}
.tamarind-celebration.celebrate-singapore-page .banner-div h2 {
  margin-top: 112px;
  margin-bottom: 100px;
}
.celebration-and-event-page .celebration_cards_main .normal-card-section {
  position: relative;
  margin-top: 24px;
  padding-top: 315px;
  padding-bottom: 275px;
}
.celebration-and-event-page .experience-div-section {
  padding: 110px 0 250px;
}
.celebrate-singapore-page
  .celebration_cards_main
  .normal-card-div:nth-child(2) {
  margin-top: 120px;
}
.exclusive-offer-page .travel-section .slick-slide:first-child .normal-card-div,
.vskl-home .room-div-slide .react-multi-carousel-item:nth-child(4) {
  margin-top: 0 !important;
}
.celebration_cards_main .normal-card-div:nth-child(6),
.mt-100 {
  margin-top: 100px;
}
.celebration_cards_main .normal-card-section {
  padding: 80px 0 120px;
}
.tab-button .slider-count-text span.active::before {
  content: "";
  background-image: url(/src/assets/images/select-line.png);
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -10px;
}
.banner-div h2 {
  color: #d9ccc5;
  font-size: 45px;
}
.big-font {
  font-size: 62px;
  color: #d9ccc5;
}
.celebrate-singapore-page .text-description-div p {
  margin-top: 85px;
  font-size: 16px;
  margin-bottom: 120px;
}
.celebrate-singapore-page .text-description-div {
  padding: 130px 0;
  position: relative;
  border: none;
}
.celebrate-singapore-page .tab-button {
  position: relative;
  padding: 43px 0 0;
}
.celebrate-singapore-page .tab-button span {
  font-size: 22px;
}
.event-card-small .cards.heading h4 {
  font-size: 45px;
  margin-bottom: 90px;
}
.celebrate-singapore-page .celebration_cards_main {
  margin-top: 35px;
}
.text-description-div {
  padding: 70px 0;
  font-family: rotis-sans-serif;
}
.event-card {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}
.tamarind-celebration.celebrate-singapore-page .event-card {
  padding-top: 130px;
  padding-bottom: 107px;
}
.exclusive-offer-page .stay-section {
  padding: 55px 0 0;
}
.exclusive-offer-page .stay-section .btn-slider-div {
  padding: 16px 0 0;
}
.exclusive-offer-page .stay-section .normal-card-section {
  position: relative;
  padding: 50px 0 60px;
}
.exclusive-offer-page .taste-section .normal-card-section::before {
  position: absolute;
  content: "";
  background-image: url(../src/assets/images/border-btm.png);
  left: 53px;
  height: 2px;
  width: 93%;
  bottom: 0;
}
.exclusive-offer-page .travel-section {
  padding: 95px 0 185px;
}
.exclusive-offer-page .taste-section,
.room-type-two .crib-roomtype {
  padding: 50px 0 0;
}
.bg-cover {
  background: url("../src/assets/images/stay-grey.png") center top no-repeat;
}
.card-text .cards.heading {
  padding: 35px 0 0;
}
.-mt-130 {
  margin-top: -130px;
}
.-mt-30 {
  margin-top: -30px;
}
.center-slider .slick-slide {
  background-color: transparent;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-slider .slick-slide,
.center-slider
  .slick-slide[aria-hidden="true"]:not(.slick-cloned)
  ~ .slick-cloned[aria-hidden="true"] {
  transform: scale(0.9, 0.9);
  transition: 0.4s ease-in-out;
}
.center-slider .slick-center,
.center-slider .slick-current.slick-active,
.center-slider
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  transform: scale(1);
  background-color: transparent;
}
.vskl-home .page-description-div h3 {
  font-size: 62px;
  margin-bottom: 80px;
}
.day-trips-section .slick-slide {
  padding: 10px;
}
.pt-200 {
  padding-top: 200px !important;
}
.pb-120 {
  padding-bottom: 120px;
}
.ex-title {
  margin: 0;
  font-size: 45px;
  color: #d9ccc5;
}
.pt-20 {
  padding-top: 20px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-150 {
  padding-bottom: 150px;
}
.h-64 {
  height: 64px;
}
.pb-95 {
  padding-bottom: 95px;
}
.MuiDataGrid-virtualScroller,
.mt-56 {
  margin-bottom: 56px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-170 {
  padding-bottom: 170px;
}
.pb-109 {
  padding-bottom: 109px;
}
.content-text-style p {
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 22px;
}
.pb-213 {
  padding-bottom: 213px;
}
.card-description img {
  margin-bottom: 55px;
}
.card-description h4 {
  color: #d9ccc5;
  font-size: 45px;
  margin-bottom: 25px;
}
.card-description p,
.job-apply .page-description p {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
}
.btn-reserbox {
  margin-top: 44px;
}
.res-btn {
  border: 1px dashed #fff;
  background-image: url(../src/assets/images/btn.png);
  color: #d9ccc5;
  font-size: 24px;
  padding: 5px 25px;
}
img {
  max-width: 100%;
}
.address-list.tamarind-resort,
.gift-modal-content,
.mt-150 {
  margin-top: 150px;
}
.mb-200 {
  margin-bottom: 200px;
}
.gallery-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vskl-experience h3 {
  font-size: 62px;
  text-align: center;
  color: #403836;
  padding: 122px 0 96px;
}
.experience-section-page .sub-header-div::after {
  background-image: url(../src/assets/images/border-btm.png);
}
.experience-section-page .experience-content-text {
  padding: 115px 0 200px;
  position: relative;
}
.experience-section-page .vsklexprice-banner {
  padding: 99px 0 0;
}
.experience-section-page .exclusiveboox::before {
  position: absolute;
  content: "";
  background-image: url(../src/assets/images/greyline.png);
  left: 0;
  height: 1px;
  width: 100%;
  top: 0;
}
.border-dashed-ex:after,
.experience-section-page .experience-content-text::before {
  background-image: url(../src/assets/images/border-btm.png);
  position: absolute;
  content: "";
  left: 0;
}
.experience-section-page .experience-content-text::before {
  height: 1px;
  width: 100%;
  bottom: 0;
}
.experience-content-text p {
  font-size: 28px;
  color: #403836;
  line-height: 38px;
}
.py-100 {
  padding: 100px 0;
}
.center-slider.exprcne .slick-slide {
  height: auto;
  background: 0 0;
  color: #fff;
}
.exprice-slider img {
  margin-bottom: 45px;
}
.exprice-slider h3 {
  font-size: 28px;
}
.exprice-slider a {
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
}
.py-180 {
  padding: 180px 0;
}
.py-80 {
  padding: 80px 0;
}
.page-description p {
  color: #fff;
  text-align: center;
}
.font-62 {
  font-size: 62px;
}
.py-200 {
  padding: 160px 0;
}
.experience-section-page .section.exp-bg {
  background: url("../src/assets/images/expricence/white-exp.png") 0 425px/100%
    100% no-repeat;
}
.experience-section-page .samadhian-div {
  padding-bottom: 60px;
}
.c-slide_image {
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.c-move-to-bottom {
  transform: translateY(80px);
}
:focus-visible {
  outline: 0;
}
.exclusiveboox,
.wellness-vskl .banner-div h2 {
  padding: 50px 0;
}
.exclusiveboox h3 {
  margin: 0;
  font-size: 45px;
}
.exclusiveboox button {
  border: 1px dashed;
  width: 225px;
  height: 47px;
  font-size: 16px;
}
.room-features ul {
  margin: 0;
  padding-left: 13px;
}
.room-features ul li {
  color: #fff;
  width: 99%;
  line-height: 44px;
  font-size: 16px;
}
.vskl-home .other-sect .react-multi-carousel-item:nth-child(4) {
  margin-top: 50px !important;
}
.travel-motorbike h3 {
  text-align: center;
  color: #d9ccc5;
  font-size: 62px;
  padding: 90px 0;
}
.py-170 {
  padding: 170px 0;
}
.travel-motorbike-tours p {
  font-size: 45px;
  color: #d9ccc5;
  line-height: 60px;
}
.moterbike-title {
  font-size: 28px;
  color: #d9ccc5;
  padding: 77px 0 54px;
  margin: 0;
}
.pb-152 {
  padding-bottom: 152px;
}
.map-size {
  padding: 195px 0 266px;
}
.pin {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pin span {
  transform: rotate(45deg);
}
.list-moterbike {
  padding-right: 40px;
}
.list-moterbike ul {
  margin: 0;
  padding-left: 16px;
  color: #d9ccc5;
  line-height: 40px;
}
.listprice ul,
.moterbikemap-list {
  padding: 0;
  list-style: none;
}
.moterbikemap-list {
  margin: 0;
}
.moterbikemap-list li {
  display: flex;
  align-items: center;
  color: #c7b299;
  font-size: 16px;
  margin-bottom: 25px;
}
.about-us .sense-of-place h3,
.moterbike-day h3 {
  margin-bottom: 24px;
}
.moterbikemap-list li .pin {
  margin-right: 16px;
}
.content-moterbike {
  padding: 123px 0 167px;
}
.border-dashed-ex:after {
  height: 2px;
  width: 100%;
  top: 0;
}
.border-dashed-ex:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
}
.moterbike-day {
  padding-right: 24px;
  font-family: RotisSerif !important;
}
.moterbike-day p {
  margin: 0;
  line-height: 22px;
}
.listprice ul,
.moterbike-day h6 {
  color: #fff;
  font-size: 16px;
  margin: 0;
}
.price-content-box {
  padding: 129px 0 224px;
}
.listprice.listnopric ul li {
  list-style-type: disc;
  display: list-item;
}
.enquirt-section {
  padding: 0 0 240px;
}
.enquiry-input,
.enquiry-textarea {
  background: #d9ccc5;
  box-sizing: border-box;
  padding: 6px 12px;
  font-size: 16px;
  border: 1px solid #707070;
  outline: 0;
}
.enquiry-form h3 {
  color: #d9ccc5;
  font-size: 45px;
  padding-bottom: 150px;
}
.enquiry-input {
  height: 81px;
}
.enquiry-textarea {
  height: 359px;
  color: #73625c;
}
.btn-send,
.btn-send:hover {
  background: #a6958f !important;
  border: 1px solid #707070;
  height: 81px;
  font-size: 24px;
  text-transform: uppercase;
  color: #050505;
}
.experience-bg,
.experience-section-page,
.experience-section-page .sub-header {
  background-color: #f8f4f4;
}
.pt-270 {
  padding-top: 270px;
}
.experience-bg,
.pt-100 {
  padding-top: 100px;
}
.list-moterbike li {
  font-size: 28px;
  line-height: 40px;
}
.experience-thaipusam-page-one .sub-header-div::after {
  background-image: url(../src/assets/images/greyline.png);
}
.banner-slider-card li,
.experience-section-page .banner-div,
.vskl-home .vskl-number-slide .images-room .react-multi-carousel-list,
.vskl-number-slide .btn-slider-div .images-room li {
  padding-bottom: 0;
}
.experience-section-page.experience-page .page-description {
  padding: 190px 0 160px;
  position: relative;
}
.experience-section-page.experience-page .page-description::after {
  content: "";
  background-image: url(/src/assets/images/border-btm.png);
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.experience-section-page .banner-slider-card .slider-count-text {
  margin-top: 15px;
  color: #403836;
  font-size: 24px;
}
.experience-section-page .banner-slider-card .slick-prev:before {
  content: "";
  background-image: url(../src/assets/images/arrow.svg);
  color: #403836;
}
.about-us .cards-big.last-new:after,
.experience-section-page .border-btm::before {
  height: 2px;
  background-image: url(../src/assets/images/border-btm.png);
}
.experience-section-page .sub-header h3 {
  color: #050505;
  font-size: 32px;
}
.experience-thaipusam-page.experience-section-page .experience-content-text {
  padding: 115px 0 180px;
  position: relative;
}
.experience-section-page .sub-header span svg {
  color: #202020;
}
.experience-section-page .heading-experience {
  color: #73625c;
}
.experience-section-page .day-trips-section.discover-div {
  padding: 150px 0;
}
.celebration-enquiry .radio-input {
  display: flex;
}
.navbar-main .menu-btn-div {
  width: 109px;
  text-align: left;
  margin: 0;
  padding-left: 0;
  justify-content: start;
}
.crms-heading,
.flex-container .text-center {
  text-align: center;
}
.btn-slider-div .slick-next {
  right: 45px;
  opacity: 0.5;
}
.btn-slider-div .slick-prev {
  left: 35px;
  z-index: 9;
  opacity: 0.5;
}
.btn-slider-div .slick-next:before,
.btn-slider-div .slick-prev:before {
  font-size: 30px;
  color: #d9ccc5;
}
.btn-slider-div .react-multiple-carousel__arrow--left,
.btn-slider-div .react-multiple-carousel__arrow--right {
  top: 30%;
  background-color: #d9ccc5;
  opacity: 0.5;
}
.btn-slider-div .react-multiple-carousel__arrow:hover {
  opacity: 1;
  background-color: #d9ccc5 !important;
}
.about-us .ab-privacy h3 {
  margin-bottom: 23px;
}
.about-us .cards-big .cards.heading {
  width: 85%;
  padding-left: 100px;
}
.about-us .sense-of-place p {
  font-size: 28px;
  color: #d9ccc5;
  line-height: 38px;
  margin-bottom: 77px;
}
.about-us .page-description-div.ab-privacy p {
  line-height: 38px;
  margin-bottom: 55px;
}
.about-us .small-div-card .img-section {
  padding: 105px 0 105px 110px;
}
.about-us .about-privacy p,
.detail-section h3 {
  color: #d9ccc5;
  font-size: 28px;
}
.about-us .banner-div + .page-description-div {
  padding: 153px 0 165px !important;
}
.about-us .cards.heading h4 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 400 !important;
}
.about-us .page-description-div + .cards-big .normal-card-section {
  padding: 50px 0 124px;
}
.about-us .sense-of-place a {
  text-decoration: none;
  font-size: 20px;
  color: #d9ccc5;
}
.about-us .page-description-div + .cards-big + .page-description-div {
  padding: 70px 0 150px !important;
}
.about-us
  .page-description-div
  + .cards-big
  + .page-description-div
  + .big-and-small-div {
  padding-bottom: 155px;
}
.about-us
  .page-description-div
  + .cards-big
  + .page-description-div
  + .big-and-small-div
  + .cards-big {
  padding-bottom: 45px;
}
.about-us p.relax-line {
  margin-top: 140px !important;
}
.about-us
  .page-description-div
  + .cards-big
  + .page-description-div
  + .big-and-small-div
  + .cards-big
  + .page-description-div {
  padding: 70px 0 115px !important;
}
.about-us .detail-section {
  margin-top: 122px;
  width: 70%;
}
.about-us .big-div-card .detail-section {
  padding-left: 100px;
}
.about-us .big-and-small-div .detail-section {
  margin-bottom: 160px;
}
.about-us .big-and-small-div a {
  font-size: 20px;
  text-decoration: none;
  color: #fff;
}
.about-us .cards-big.last-new {
  position: relative;
  padding: 225px 0 295px;
}
.about-us .transparent-about-div {
  background: linear-gradient(180deg, #403836 0, #403836 50%, #625754 100%)
    no-repeat padding-box;
}
.about-us .cards-big.last-new:after {
  left: 0;
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
}
.about-us .react-multi-carousel-list {
  overflow-x: hidden !important;
  overflow: inherit;
}
.about-us .react-multi-carousel-list::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
.about-us .day-trips-section {
  padding: 85px 0 0;
}
.contact-us-page .address-div-pic ul.address-detail {
  padding: 0;
  width: 70%;
}
.contact-us-page ul.address-detail li {
  color: #d9ccc5;
  font-size: 16px;
  list-style: none;
}
.contact-us-page .address-div-pic .address-city {
  font-family: Rotis-Sans-Serif-Bold-65;
  color: #d9ccc5;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}
.contact-us-page .address-div-pic .address-title {
  font-size: 28px;
  color: #f8f4f4;
  margin-bottom: 22px;
  white-space: nowrap;
}
.contact-us-page .address-div-pic svg {
  color: #d9ccc5;
  font-size: 37px;
}
.contact-us-page .contact-cards {
  margin-bottom: 25px;
}
.contact-us-page .banner-heading {
  padding: 55px 0 42px;
}
.job-card-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #d9ccc5;
  margin: 5px 0;
  padding: 40px 60px;
  border: 1px solid #707070;
  height: 150px;
}
.job-apply .job-card-flex .small-title {
  font-size: 12px;
  text-transform: uppercase;
  font-family: Rotis-Sans-Serif-Bold-65;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
}
.job-apply .job-card-flex .apply-btn-div {
  margin-top: 15px;
}
.property-manager-div h4 {
  font-size: 24px;
  margin-bottom: 30px;
}
.job-apply .job-card-flex .dashed-btn {
  width: 155px;
  height: 45px;
  background-image: url(../src/assets/images/small-border.png);
  color: #403836 !important;
  font-size: 16px !important;
}
.job-apply button.read-more-btn {
  background: 0 0;
  border: #ffdead;
}
.job-detail-page {
  margin-top: 20px;
  height: 832px;
  overflow-y: auto;
  width: 874px;
  padding: 0 90px;
}
.job-detail-page::-webkit-scrollbar {
  cursor: pointer;
  width: 7px;
  border-radius: 5px;
  scrollbar-width: thin;
}
.job-detail-page::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.job-detail-page::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.job-detail-page::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}
.job-apply .banner-div h2 {
  padding: 100px 0;
  font-size: 62px;
}
.job-apply .page-description {
  padding: 115px 0 0;
}
.job-apply .vacancies-section {
  padding: 27px 0;
}
.job-apply .job-apply-card {
  margin-top: 120px;
  padding-bottom: 40px;
}
.job-apply .close-job-detail {
  right: -6px;
  position: relative;
  cursor: pointer;
}
.job-form-page .form-card-div {
  background-color: #d9ccc5;
  padding: 45px 125px 45px 60px;
  border: 1px solid #707070;
  margin-bottom: 20px;
}
.job-form-page .form-card-div:nth-child(2),
.job-form-page .form-card-div:nth-child(3) {
  padding-bottom: 80px;
}
.job-form-page .form-card-div h4 {
  font-size: 24px;
  margin-bottom: 15px;
}
.job-form-page input.job-input {
  width: 100%;
  border: 1px solid #707070;
  margin-bottom: 10px;
  height: 44px;
  padding: 20px;
  color: #403836;
  font-size: 16px;
}
.job-form-page input::placeholder {
  color: #403836;
  font-size: 16px;
}
.job-form-page .dashed-btn {
  width: 160px;
  color: #403836 !important;
  padding: 7px 56px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  font-size: 16px !important;
  height: 40px;
  background-image: url("../src/assets/images/small-border.png");
}
.job-form-page .form-section {
  padding: 0 0 365px;
}
.job-form-page .text-data-div h4 {
  font-size: 28px;
  color: #d9ccc5;
}
.job-form-page .text-data-div p {
  color: #d9ccc5;
  font-size: 16px;
  margin: 0;
}
.job-form-page h3 {
  margin-bottom: 77px;
}
.job-form-page .text-data-div {
  margin-bottom: 35px;
}
.job-form-page {
  margin-top: 70px;
}
.job-form-page .upload-btn-section {
  position: relative;
  padding-left: 80px;
}
.job-form-page .upload-btn-section input {
  position: absolute;
  width: 100%;
  height: 53px;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}
.close-job-detail {
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
}
.admin_layout .top_loader_div,
.top_loader_div {
  top: 0;
  position: fixed;
}
.exclusive-card-data .white-input-small {
  width: 75px;
  padding: 0 20px;
  font-size: 28px;
}
.gift-card-experience .main-bg {
  padding-bottom: 520px;
}
.digital-gift-card .purchase-div {
  margin-top: 180px;
}
.digital-gift-card .exclusive-newnew {
  padding-bottom: 240px;
}
.digital-gift-card .exclusive-newnew::before {
  content: none;
  border: none;
}
.btn-slider-div .react-multi-carousel-item img {
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 1s;
  transition: 1s;
}
.arrowspanleft svg,
.back_menu svg {
  transform: rotate(180deg);
}
.room-type-two .exprcne.ex-homepage.btn-slider-div .react-multi-carousel-item {
  -webkit-transition: 3s;
  transition: 3s;
}
.room-type-two
  .exprcne.ex-homepage.btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active
  + .react-multi-carousel-item.react-multi-carousel-item--active {
  transform: translateY(55px);
  position: absolute;
  bottom: 0;
  padding-bottom: 150px;
  z-index: 9999;
}
.room-type-two
  .exprcne.ex-homepage.btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2n) {
  transform: translateY(0);
}
.btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2n) {
  transform: translateY(55px);
  position: absolute;
  bottom: 0;
  -webkit-transition: 2s;
  transition: 2s;
  padding-bottom: 150px;
  z-index: 9999;
}
.stay-home + .footer-sub {
  margin-bottom: 85px;
}
.text-banner-div {
  min-height: 800px;
}
.quill {
  background: #fff;
}
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
  background-color: #625754;
}
.flex-container .text-center h1,
.flex-container .text-center h3 {
  margin: 10px;
  cursor: default;
}
.flex-container .text-center h1 .fade-in,
.flex-container .text-center h3 .fade-in {
  animation: 2s infinite fadeIn;
}
.flex-container .text-center h1 {
  font-size: 8em;
  transition: font-size 0.2s ease-in-out;
  border-bottom: 1px dashed #fff;
}
.flex-container .text-center h1 span#digit1 {
  animation-delay: 0.2s;
}
.flex-container .text-center h1 span#digit2 {
  animation-delay: 0.3s;
}
.flex-container .text-center h1 span#digit3 {
  animation-delay: 0.4s;
}
.flex-container .text-center button {
  border: 1px solid #fff;
  background: 0 0;
  outline: 0;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in;
  margin: 20px 0;
}
.flex-container .text-center button:hover {
  background-color: #fff;
  color: #555;
  cursor: pointer;
}
@keyframes colorSlide {
  0%,
  100% {
    background-color: #152a68;
  }
  25% {
    background-color: #4169e1;
  }
  50% {
    background-color: #2e8b57;
  }
  75% {
    background-color: tomato;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.img-crop-modal .img-crop-container {
  position: relative;
  width: 100%;
  height: 72vh !important;
}
.crms-heading {
  padding: 50px 50px 35px;
}
.digital-gift-card .enquiry-input::placeholder {
  color: #403836;
}
.gift-modal-content h5 {
  color: #403836;
  font-weight: 600;
}
.gift-modal-content .white-btn {
  border: 1px solid #707070 !important;
}
.gift-modal-content .h2 {
  font-size: 35px;
  color: #403836;
}
.celebrate-customer-div h3 {
  color: #050505;
  font-style: italic;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}
.accolades-list-div h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}
.accolades-list-div ul {
  padding: 0;
  margin-bottom: 25px;
}
.accolades-list-div ul li {
  list-style: none;
  font-size: 16px;
}
.privacy-notes .crms-heading::after {
  content: "";
  background-image: url(../src/assets/images/border-btm.png);
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.gift-image-section .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fff;
}
.gift-image-section .slick-dots li button:before {
  font-size: 10px;
  border-radius: 100%;
}
.gift-image-section img {
  border-radius: 20px;
}
.multi-images .ant-upload-list-item-container {
  display: none !important;
}
.ant-upload-list {
  display: flex;
  width: 100%;
}
.sub-header ul li button.active::before {
  content: "";
  background-image: url(/src/assets/images/select-line.png);
  height: 5px;
  width: 75%;
  position: absolute;
  bottom: 3.5px;
}
.ant-upload-wrapper {
  width: 120px !important;
  display: block !important;
}
.single_image_upload .ant-upload-list-item-container {
  width: auto;
}
span.ant-upload {
  background-color: transparent;
  border-radius: 10px;
}
.small-btn-inc {
  font-size: 20px !important;
  color: #fff;
  padding: 6px 15px;
  border: 1px dashed #d9ccc5 !important;
}
.small-msg {
  font-size: 10px;
}
.table_height_3000 {
  height: 3000px;
}
.table_height_1000 {
  height: 1000px;
}
.table_height_2000 {
  height: 1935px;
}
.table_height_1300 {
  height: 1300px;
}
.table_height_800 {
  height: 1200px;
}
.table_height_400 {
  height: 650px;
}
.property-manager-div {
  width: 330px;
  text-overflow: ellipsis;
}
.category-div,
.location-div {
  width: 160px;
}
.top_loader_div {
  width: 100%;
  height: 5px;
  z-index: 9999;
}
.top_loader_div .progress-bar {
  background-color: #d9ccc5;
  height: 6px;
}
.top_loader_div .progress {
  height: 2px;
  width: 100%;
  background-color: #403836;
}
.progress-value {
  animation: 10s forwards load;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background-color: #d9ccc5;
  height: 5px;
  width: 0;
}
@keyframes load {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
.Mui-checked .PrivateSwitchBase-input,
.switch_admin .MuiSwitch-switchBase.Mui-checked {
  color: #403836;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #403836 !important;
}
.MuiSelect-select {
  padding: 5px !important;
}
.text-black {
  color: #000;
}
.gallery-images.multi-images img {
  height: 55px;
  margin: 0 0 10px;
  float: right;
}
.file_upload_div {
  background-color: #fff;
  padding: 20px 5px 5px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.file_upload_div svg {
  font-size: 30px;
  margin-bottom: 10px;
  color: #403836;
}
.file_upload_div p {
  line-height: 15px;
  color: #403836;
}
.ant-upload-list-item {
  width: 102px !important;
}
.gallery-one .slick-slide img {
  padding: 5px 50px;
  width: 100%;
  height: 95vh;
  object-fit: contain;
}
.gallery-one .btn-slider-div .slick-prev {
  left: -65px;
}
.gallery-one .btn-slider-div .slick-next {
  right: -65px;
}
.tamarind-spring .gallery-tamarind img {
  max-height: 500px;
}
.banner_single img {
  width: 100%;
  height: 55px;
}
.ant-modal {
  top: 10px;
}
:where(.css-dev-only-do-not-override-diro6f).ant-modal .ant-modal-footer {
  margin-top: 0;
}
.banner-slider-card.btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2n),
.banner-slider-card.btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(odd) {
  transform: none !important;
  transition: none;
  padding-bottom: 0;
}
.travel_map img {
  height: 930px;
  width: auto;
  margin: 0 auto;
}
ul.side-menu-list li:last-child {
  cursor: pointer;
  color: #d9ccc5;
  font-size: 20px;
  list-style: none;
  opacity: 0.5;
}
.tab_retreat_detail_page {
  padding-top: 60px;
  padding-bottom: 60px;
}
.left_tabs_btn button {
  position: relative;
  align-items: flex-start;
  color: #d9ccc5;
  background-color: transparent;
  font-size: 20px;
  text-transform: capitalize !important;
  text-align: left;
}
.dropdown_menu_new h6,
.loading__letter {
  font-weight: 400;
  text-transform: uppercase;
}
.tab_retreat_detail_page,
.tab_retreat_detail_page button.Mui-selected,
.tab_retreat_detail_page li,
.tab_retreat_detail_page p,
.tab_retreat_detail_page span,
.tab_retreat_detail_page strong,
.wellness-vskl h1,
.wellness-vskl h3,
.wellness-vskl h4,
.wellness-vskl h5,
.wellness-vskl p,
.wellness-vskl span,
.wellness-vskl strong {
  color: #d9ccc5 !important;
}
.tab_retreat_detail_page button.Mui-selected:before {
  content: "";
  content: "";
  background-image: url(/src/assets/images/select-line.png);
  height: 5px;
  width: 70%;
  position: absolute;
  bottom: 8px;
}
.dropdown_menu_new a:hover::before,
.getting_text:hover::before {
  content: "";
  height: 2px;
  left: 0;
  background-image: url(/src/assets/images/border-pic.svg);
  bottom: -3px;
  transform: rotate(180deg);
}
.default_not_used li {
  position: absolute;
  left: 55px;
  bottom: 33px;
}
.left_tabs_btn {
  margin-top: 11px;
}
.loading_text {
  display: flex;
  flex-direction: row;
}
.loading__letter {
  font-size: 24px;
  letter-spacing: 4px;
  color: #fff;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.loading__letter:nth-child(2) {
  animation-delay: 0.1s;
}
.loading__letter:nth-child(3) {
  animation-delay: 0.2s;
}
.loading__letter:nth-child(4) {
  animation-delay: 0.3s;
}
.loading__letter:nth-child(5) {
  animation-delay: 0.4s;
}
.loading__letter:nth-child(6) {
  animation-delay: 0.5s;
}
.loading__letter:nth-child(7) {
  animation-delay: 0.6s;
}
.loading__letter:nth-child(8) {
  animation-delay: 0.8s;
}
.loading__letter:nth-child(9) {
  animation-delay: 1s;
}
.loading__letter:nth-child(10) {
  animation-delay: 1.2s;
}
@keyframes bounce {
  0%,
  100%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
}
.exclusive-newnew .bottom-cards-div {
  margin-top: 96px;
}
.exclusive-newnew .bottom-cards-div .cards.heading {
  margin-bottom: 32px;
}
.retreats-celebration .banner-div {
  padding: 0 0 60px;
}
.retreats-celebration .celebration_cards_main .normal-card-section {
  padding: 20px 0 0;
}
.vskl-taste .banner-div {
  padding: 0 0 78px;
}
.experience-page #celebrations h3,
.experience-page #culture h3,
.experience-page #samadhian h3,
.experience-page #senses h3 {
  padding: 96px 0;
}
.room-type-page .content-text-style {
  padding: 41px 0 85px;
}
.room-type-page .content-cardbox {
  padding: 0 0 55px;
}
.room-type-two .ex-homepage {
  padding: 58px 0 60px;
}
.room-type-page .day-trips-section {
  padding: 103px 0 52px;
}
.celebration-enquiry .enquiry-form {
  padding: 44px 0 0;
}
#demo-multiple-checkbox {
  white-space: pre-wrap;
}
.ant-dropdown {
  top: 80px !important;
  position: fixed;
  z-index: 99999999 !important;
}
.ant-dropdown-menu-item {
  background-color: #403836;
  color: #fff !important;
}
.dropdown_menu_new {
  background-color: #403836 !important;
  width: 350px;
  color: #d9ccc5;
  padding: 35px;
}
.dropdown_menu_new a,
.dropdown_menu_new a:hover {
  color: #d9ccc5;
  font-size: 16px;
  line-height: 21px;
  position: relative;
}
.dropdown_menu_new a:hover::before {
  width: 100%;
  position: absolute;
}
.dropdown_menu_new h6 {
  margin-bottom: 4px;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 13px;
}
.menu-btn-div img {
  width: 22px;
}
.ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rotate_arrow,
.rotate_back {
  transition: 0.5s;
}
.travel-page-home .banner-div {
  margin-top: 52px;
}
.room-type-two .btn-reserbox {
  margin-top: 45px;
  margin-bottom: 20px;
}
.vskl-taste .banner-slider-card .react-multi-carousel-item:nth-child(11),
.vskl-taste .banner-slider-card .react-multi-carousel-item:nth-child(4),
.vskl-taste .banner-slider-card .react-multi-carousel-item:nth-child(7) {
  margin-top: 0;
}
.switch_admin .Mui-checked + .MuiSwitch-track {
  background-color: #8e7d79 !important;
}
.side-menu-list .open_menu_click:first-child svg,
.side-menu-list .open_menu_click:nth-child(2) svg {
  color: #d9ccc5;
  cursor: pointer;
  display: block;
}
.side-menu-list .open_menu_click svg {
  display: none;
  color: red;
}
.show_menu_data ul.side-menu-list li:last-child {
  opacity: 1;
  pointer-events: inherit;
}
.text_sidemenu {
  color: #d9ccc5;
  margin: 10px 0;
  text-transform: inherit !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.back_menu svg {
  color: #d9ccc5;
  margin-right: 10px;
}
.rotate_arrow {
  transform: rotate(90deg);
}
.wellness-vskl .day-trips-section .day-trip-section-bordered {
  position: relative;
  padding: 110px 0 30px;
}
.getting_text:hover::before {
  width: 120px;
  position: absolute;
}
.footer-logo-check {
  width: 125px;
}
.exclusive-card-data a,
.exclusive-card-data b,
.exclusive-card-data li,
.exclusive-card-data li span,
.exclusive-card-data strong,
.page-description-div p span,
.ql-align-center span,
.room-type-page .content-text-style p span,
.vskl-ex-offer a,
.vskl-ex-offer li a,
.vskl-ex-offer p,
.vskl-ex-offer small,
.vskl-ex-offer strong,
.vskl-taste .banner-div p span,
.wellness-vskl .cards.heading p {
  color: #fff !important;
}
.tamarind-spring .content-none .react-multi-carousel-item {
  transition: 1.5s;
}
.btn-slider-div li:first-child .card-design {
  padding-left: 0;
  margin-left: 0;
}
.footer-main .first_section_link {
  padding-left: 20px !important;
}
.btn-slider-div li:nth-child(7) .bottom-cards-div.px-3 {
  padding-left: 0 !important;
  margin-left: 0;
}
.mobile_footer {
  padding: 0 !important;
}
.vskl-home
  .number-slider.vskl-number-slide
  .btn-slider-div
  .react-multi-carousel-item.react-multi-carousel-item--active {
  transform: translateY(55px);
}
.crossSideMenu svg path {
  stroke: #d9ccc5;
}
.tamarind-spring .scroll-slider-div.content-none.btn-slider-div img {
  max-height: 650px;
  min-height: 200px;
}
.event_block_page.tamarind-celebration .event-card-small .cards.heading h4 {
  margin-bottom: 20px;
}
.room-type-page .book-offer-div {
  position: relative;
  padding: 75px 0 50px;
}
.tamarind-spring .tabs-section.intro_text_tab {
  padding: 55px 0 40px;
}
.vskl-home
  .vskl-number-slide
  .images-room
  .react-multiple-carousel__arrow--left {
  bottom: 105px;
  left: 40%;
}
.vskl-home
  .vskl-number-slide
  .images-room
  .react-multiple-carousel__arrow--right {
  bottom: 100px;
  right: 40%;
}
.vskl-number-slide
  .btn-slider-div
  .images-room
  .react-multiple-carousel__arrow--left,
.vskl-number-slide
  .btn-slider-div
  .images-room
  .react-multiple-carousel__arrow--right {
  top: auto;
  display: block;
  bottom: 150px;
  opacity: 1;
  min-width: 30px;
  min-height: 30px;
}
.vskl-number-slide
  .btn-slider-div
  .images-room
  .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2n) {
  transform: translateY(0);
  position: relative;
  padding-bottom: 0;
  min-width: 30px;
  min-height: 30px;
}
.stay-home .sub-header .MuiSelect-select {
  pointer-events: none;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 8px;
  width: 7px;
  border-radius: 5px;
  scrollbar-width: thin;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #403836;
  border-radius: 5px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #403836;
  border-radius: 5px;
}
.package_duration_texteditor small {
  font-size: 12.8px !important;
  font-weight: 400;
}
.package_duration_texteditor code {
  font-size: 14px !important;
  font-weight: 400;
}
.package_duration_texteditor b,
.package_duration_texteditor strong {
  font-size: inherit !important;
  font-weight: 700;
}
.package_duration_texteditor abbr,
.package_duration_texteditor cite,
.package_duration_texteditor li,
.package_duration_texteditor mark,
.package_duration_texteditor ol,
.package_duration_texteditor p,
.package_duration_texteditor pre,
.package_duration_texteditor span,
.package_duration_texteditor ul {
  font-size: 16px !important;
  font-weight: 400;
}
.package_duration_texteditor a {
  font-size: 16px !important;
  font-weight: bolder;
  text-decoration-color: #d9ccc5 !important;
  color: #d9ccc5 !important;
}
.package_duration_texteditor h1 {
  font-size: 32px !important;
  font-weight: 700;
}
.package_duration_texteditor h2 {
  font-size: 24px !important;
  font-weight: 700;
}
.package_duration_texteditor h3 {
  font-size: 18.72px !important;
  font-weight: 700;
}
.package_duration_texteditor h4 {
  font-size: 16px !important;
  font-weight: 700;
}
.package_duration_texteditor h5 {
  font-size: 13.28px !important;
  font-weight: 700;
}
.package_duration_texteditor h6 {
  font-size: 10.72px !important;
  font-weight: 700;
}
@media screen and (min-width: 1920px) and (max-width: 2555px) {
  .arrowspan,
  .arrowspanleft {
    cursor: pointer;
    top: 47px !important;
    position: relative;
  }
  .taste-page.tamarind-spring .celebration-internal-div img {
    height: 650px !important;
  }
  .gallery-div-masonary::before {
    width: 97.5%;
  }
  .taste-home .experience-div-section {
    padding: 150px 0 315px;
  }
  .vskl-home .day-trips-section.other-sect {
    padding: 200px 0 190px;
  }
  .page-description p {
    line-height: 60px;
  }
  .experience-section-page .page-description p {
    font-size: 45px;
    color: #403836;
  }
  .cards.heading {
    margin-bottom: 145px;
  }
  .card-description {
    margin-bottom: 200px;
  }
  .contact-us-page .address-div-pic ul.address-detail {
    padding: 0;
    width: 75%;
  }
  .text-banner-div {
    min-height: 1128px;
  }
  .nav-menu-section {
    padding: 75px 0 40px;
  }
  .vskl-ex-offer .exclusive-newnew img {
    height: 725px;
  }
  .tamarind-spring .tabs-section li {
    list-style: none;
    font-size: 28px;
  }
  .tamarind-spring .tabs-section li button {
    font-size: 28px !important;
  }
  .side-menus-div .small-title,
  .stay-home .sub-header button,
  .stay-page .page-description-div .page-description-data p,
  .sub-header li a {
    font-size: 16px;
  }
  .vskl-gallery-modal.gallery-one .react-multi-carousel-item img {
    height: 532px;
    width: 100%;
    opacity: 0.41;
  }
  .arrowspan {
    left: 72px;
    z-index: 9;
  }
  .arrowspanleft {
    left: -72px;
  }
  .vskl-gallery-modal.gallery-one
    .react-multi-carousel-item.react-multi-carousel-item--active {
    transform: scale(1.5);
    opacity: 1;
  }
  .vskl-gallery-modal.gallery-one
    .react-multi-carousel-item.react-multi-carousel-item--active
    img {
    height: 500px;
    opacity: 1;
  }
  .footer-main::after,
  .footer-main::before {
    left: 2%;
    content: "";
    height: 2px;
    width: 98%;
  }
  .home-page .center-big-card-list:after {
    left: 3.8%;
    height: 2px;
    width: 92.3%;
  }
  .vskl-home .slider-count-text {
    margin-top: -28px;
    -webkit-margin-left: 17%;
    -moz-margin-left: 17%;
    -o-margin-left: 17%;
    -ms-margin-left: 17%;
    margin-left: 17%;
  }
  .sub-header {
    margin-top: 15px;
  }
  .travel-page-home .sub-header {
    padding: 30px 0;
  }
  .navbar-main {
    padding: 64px 0;
    height: 200px;
  }
  .taste-page .page-description-div .page-description-data p,
  .travel .page-description-div .page-description-data p {
    font-size: 45px;
  }
  .logo-header {
    width: 185px;
  }
  .home-page .page-description-div .page-description-data p {
    font-size: 45px;
    text-align: center;
    color: #d9ccc5;
  }
  .sub-header button {
    font-size: 16px;
    padding: 5px 10px !important;
  }
  .sub-header ul li:first-child button.active::before {
    bottom: -10px;
  }
  .normal-card-section .card-design img {
    width: 100%;
    height: auto;
  }
  .stay-title {
    font-size: 62px;
  }
  .cruise-page .sub-header {
    margin-top: 95px;
  }
  .menu-content span a,
  .side-menus-div label,
  ul.side-menu-list li a {
    font-size: 28px;
  }
  .side-menus-div .stay-list span {
    position: relative;
    line-height: 60px;
  }
  .side-menus-div {
    margin: 112px 0 0;
    text-align: left;
  }
  .gallery-one .react-multi-carousel-list {
    padding-bottom: 205px;
    padding-top: 115px;
  }
}
@media screen and (min-width: 1705px) and (max-width: 1920px) {
  .tamarind-spring .tabs-section li {
    padding-right: 70px;
  }
}
@media screen and (min-width: 1449px) and (max-width: 1520px) {
  .experience-slider,
  .number-slider.vskl-number-slide .btn-slider-div {
    margin-right: -100px;
  }
  .big-cards-div.retreats_div {
    margin: 0 80px 70px -88px;
  }
  .card-design-big-one .cards.heading {
    margin-left: 70px;
  }
  .taste-page.tamarind-spring .celebration-internal-div img {
    max-height: 800px !important;
  }
  .home-page .center-big-card-list:after {
    left: 6%;
    height: 2px;
    width: 88%;
  }
}
@media screen and (min-width: 1520px) and (max-width: 1580px) {
  .btn-slider-div .react-multi-carousel-item img {
    min-height: 500px;
  }
  .tamarind-spring .tabs-section li {
    padding-right: 60px;
  }
  .experience-div-section .testimonial-type-card {
    padding-left: 85px;
  }
  .big-cards-div {
    margin: 0 60px 70px 50px;
  }
  .card-design-big-one .cards.heading {
    margin-left: 60px;
  }
  .home-page .center-big-card-list:after {
    left: 4%;
    content: "";
    height: 2px;
    width: 92%;
  }
}
@media screen and (min-width: 1580px) and (max-width: 1650px) {
  .taste-page.tamarind-spring .celebration-internal-div img {
    height: 600px !important;
  }
  .btn-slider-div .react-multi-carousel-item img {
    min-height: 500px;
  }
  .experience-div-section .testimonial-type-card {
    padding-left: 92px;
  }
  .big-cards-div {
    margin: 0 95px 70px 50px;
  }
  .card-design-big-one .cards.heading {
    margin-left: 85px;
  }
  .home-page .center-big-card-list:after {
    left: 6%;
    content: "";
    height: 2px;
    width: 88%;
  }
}
@media screen and (min-width: 1290px) and (max-width: 1445px) {
  .number-slider.vskl-number-slide .btn-slider-div {
    margin-right: -90px;
  }
  .big-cards-div.retreats_div {
    margin: 0 100px 70px -70px;
  }
  .card-design-big-one .cards.heading {
    margin-left: 70px;
  }
  .experience-slider {
    margin-right: -100px;
  }
  .MuiContainer-root {
    max-width: 1260px !important;
    padding: 0 24px;
  }
  .taste-page.tamarind-spring .celebration-internal-div img {
    height: 481px;
    object-fit: cover !important;
  }
  .home-page .page-description-div {
    padding: inherit;
  }
  .home-page .center-big-card-list:after {
    left: 5.5%;
    content: none;
    height: 2px;
    width: 89%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1120px) {
  .taste-page.tamarind-spring .celebration-internal-div img {
    height: 481px;
    object-fit: cover !important;
  }
  .experience-div-section .testimonial-type-card {
    padding-left: 78px;
  }
  .big-cards-div {
    margin: 0 25px 70px 50px;
  }
  .card-design-big-one .cards.heading {
    margin-left: 30px;
  }
  .home-page .center-big-card-list:after {
    left: 2.5%;
    content: "";
    height: 2px;
    width: 95%;
  }
}
@media screen and (min-width: 1449px) and (max-width: 1900px) {
  .arrowspan,
  .arrowspanleft {
    position: relative;
    cursor: pointer;
    top: 34px !important;
  }
  .card-design img {
    width: 100%;
    margin: 0 0 65px;
  }
  .offer_slider_page .card-design img {
    object-fit: contain;
  }
  .vskl-home .slider-count-text {
    margin-left: 16%;
  }
  .vskl-gallery-modal.gallery-one
    .react-multi-carousel-item.react-multi-carousel-item--active {
    opacity: 1;
    transform: scale(1.3);
  }
  .gallery-one .react-multi-carousel-list {
    padding-bottom: 170px;
    padding-top: 80px;
  }
  .arrowspan {
    left: 70px;
    z-index: 9;
  }
  .arrowspanleft {
    left: -70px;
  }
  .vskl-gallery-modal.gallery-one
    .react-multi-carousel-item.react-multi-carousel-item--active
    img {
    height: 500px;
    opacity: 1;
  }
}
.arrowspan,
.arrowspanleft {
  position: relative;
  cursor: pointer;
  top: 1px;
}
.banner-slider-card.btn-slider-div li {
  padding: 0;
  margin: 0;
}
.btn-slider-div li {
  transition: 1.5s;
  padding: 0 1rem;
}
.btn-slider-div li:last-child {
  padding-right: 0;
}
.btn-slider-div,
.room-type-two .btn-slider-div {
  margin: 0 0 0 -1rem;
}
.banner-slider-card.btn-slider-div {
  margin: 0 !important;
}
.exclusive-offer-page .btn-slider-div,
.home-page .btn-slider-div,
.room-type-page .btn-slider-div {
  margin: 0 -1rem;
}
@media screen and (min-width: 992px) and (max-width: 1700px) {
    .cards.heading p.term_condition_heading{
        font-size: 20px;
        color: #d9ccc5!important;
        font-weight: 700;
    }
    .cards.heading p.short_description_data,.cards.heading p.term_condition_description {
        font-size: 16px;
    }
  .taste-page.tamarind-spring .celebration-internal-div img {
    height: 481px;
    object-fit: cover !important;
  }
  .banner-slider-card li {
    padding-bottom: 0;
  }
  .btn-slider-div li,
  .pb-109 {
    padding-bottom: 50px;
  }
  .taste-page .page-description-div .page-description-data p {
    font-size: 16px;
    text-align: center;
    color: #d9ccc5;
  }
  .sub-header button {
    font-size: 13px;
    padding: 5px 7px;
  }
  .cards.heading li,
  .footer-sub span,
  .job-apply .job-card-flex p.post-name,
  .job-detail-page p,
  .room-type-all .card-description p,
  .room-type-page .content-text-style p,
  .room-type-two .card-description p,
  .room-type-two .exprice-slider p,
  .stay-home .sub-header button {
    font-size: 13px;
  }
  .cards.heading h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .cards.heading p {
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  .logo-header {
    width: 150px;
    margin-left: -5px;
  }
  .sub-header h3 svg {
    width: 16px;
  }
  .sub-header h3 {
    font-size: 24px;
    display: flex;
    margin-left: -15px;
  }
  .cards.heading a {
    font-size: 14px;
  }
  .cards.heading h6 {
    font-size: 10px;
    letter-spacing: 1.2px;
  }
  .event-card-small .cards.heading h4,
  .experience-div-section .section-head h3,
  .font-45,
  .geting-text {
    font-size: 35px;
  }
  .about-us .cards.heading h4,
  .footer-main .MuiTypography-h6,
  .moterbike-title,
  .reservation-tab h3 {
    font-size: 21px;
  }
  .footer-cont span a,
  .footer-list li a {
    line-height: 15px;
    font-size: 13px;
  }
  .h-64 {
    height: 50px;
  }
  .footer-logo-check {
    width: 125px;
  }
  .social-link-footer svg {
    width: 31px;
  }
  .reservation-tab .date-piker-section input.rmdp-input,
  .reservation-tab select,
  .rmdp-input:focus,
  .social-link-footer p {
    font-size: 16px;
  }
  .reserve-head {
    font-size: 16px !important;
    width: 109px;
    justify-content: end !important;
  }
  .cruise-home.cruise-page .deck-plan p,
  .page-description-div .heading-div h5 {
    font-size: 12px;
  }
  .home-page .page-description-div .page-description-data p,
  .page-description-div .heading-div h3 {
    font-size: 28px;
  }
  .experience-content-text p,
  .home-page .page-description-div .heading-div h3 {
    font-size: 22px;
  }
  .home-page .home-tab-section button {
    font-size: 17px;
  }
  .big-cards-div .cards.heading {
    width: 86%;
  }
  .book-offer-div h3,
  .ex-title {
    font-size: 33px;
  }
  .reservation-tab .MuiFormControl-root label {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .reservation-tab .date-piker-section label {
    font-size: 10px;
  }
  .reservation-tab-main .dashed-btn {
    font-size: 21px !important;
  }
  .reservation-tab .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    content: none;
  }
  .stay-title {
    font-size: 36px;
  }
  .dashed-btn,
  .tamarind-spring .tabs-section li button {
    font-size: 18px !important;
  }
  .dashed-btn {
    width: 160px;
    height: 40px;
    padding: 5px 30px !important;
  }
  .experience-section-page .sub-header h3 {
    color: #050505;
    font-size: 26px;
  }
  .font-62,
  .vskl-experience h3 {
    font-size: 40px;
  }
  .exclusiveboox h3 {
    margin: 0;
    font-size: 35px;
  }
  .exclusiveboox button {
    height: 44px;
    width: 170px;
    background-image: url(../src/assets/images/btn.png) !important;
    background-size: 100% 100% !important;
    border: 0 !important;
  }
  .experience-bg,
  .main-bg {
    padding-top: 65px;
  }
  .experience-div-section {
    padding: 60px 0 150px !important;
  }
  .banner-div h2,
  .room-type-two .card-description h4,
  .tamarind-spring .page-description-data h3 {
    color: #d9ccc5;
    font-size: 35px;
  }
  .taste-home.taste-page .page-description-div {
    padding: 130px 0 505px !important;
  }
  .taste-home .day-trips-section {
    padding: 100px 0 40px;
  }
  .taste-home .day-trips-section .btn-slider-div,
  .travel-page-home .day-trips-section .btn-slider-div {
    padding: 10px 0 0;
  }
  .job-apply .job-apply-card,
  .stay-page .page-description-div {
    margin-top: 80px;
  }
  .stay-home .day-trips-section {
    padding: 80px 0 50px;
  }
  .travel-page-home .page-description-div {
    padding: 120px 0 100px !important;
  }
  .normal-card-section,
  .tamarind-spring .celebration-internal-div,
  .vskl-gallery .day-trips-section {
    padding: 50px 0;
  }
  .stay-home .day-trips-section .btn-slider-div {
    padding: 18px 0 0;
  }
  .travel-page-home .day-trips-section {
    padding: 140px 0 100px;
  }
  .sub-header {
    padding: 12px 0 30px;
  }
  .cruise-page .page-description-div {
    padding: 60px 0;
    padding-bottom: 150px !important;
  }
  .cruise-home.cruise-page .taste-center-card .normal-card-section {
    padding: 100px 0 0;
  }
  .cruise-page.cruise-home .deck-plan-slider,
  .vskl-gallery .day-trips-section .btn-slider-div {
    margin-top: 100px;
  }
  .banner-slider-card .slider-count-text {
    margin-top: 19px;
    font-size: 18px;
  }
  .cruise-page .deck-plan-slider .slick-slide img {
    height: 500px;
  }
  .cruise-home.cruise-page
    .taste-center-card
    .normal-card-section
    .normal-card-div:first-child {
    margin-top: 60px;
  }
  .cruise-home.cruise-page
    .taste-center-card
    .normal-card-section
    .normal-card-div:nth-child(2) {
    margin-top: 70px;
  }
  .cards.heading,
  .taste-center-card .normal-card-section .normal-card-div:first-child {
    margin-bottom: 50px;
  }
  .cruise-page .day-trips-section {
    padding: 100px 0 200px;
  }
  .celebration-and-event-page .celebration_cards_main .normal-card-section {
    padding-bottom: 140px;
  }
  .footer-sub {
    padding: 25px 0;
  }
  .exclusive-offer-page .stay-section .normal-card-section {
    position: relative;
    padding: 10px 0 40px;
  }
  .exclusive-offer-page .taste-section {
    padding: 20px 0 0;
  }
  .exclusive-offer-page .travel-section {
    padding: 70px 0 80px;
  }
  .footer-prev {
    padding: 50px 0 35px;
  }
  .vskl-experience h3 {
    padding: 45px 0;
  }
  .experience-section-page .experience-content-text {
    padding: 100px 0 150px;
  }
  .py-100 {
    padding: 70px 0;
  }
  .experience-section-page .exclusiveboox {
    padding: 40px 0;
  }
  .about-us .page-description-div {
    padding: 110px 0 130px !important;
  }
  .about-us .page-description-div + .cards-big .normal-card-section {
    padding: 50px 0 100px;
  }
  .about-us .sense-of-place p {
    margin-bottom: 60px;
  }
  .about-us .page-description-div + .cards-big + .page-description-div {
    padding: 70px 0 125px !important;
  }
  .about-us
    .page-description-div
    + .cards-big
    + .page-description-div
    + .big-and-small-div {
    padding-bottom: 120px;
  }
  .about-us
    .page-description-div
    + .cards-big
    + .page-description-div
    + .big-and-small-div
    + .cards-big {
    padding-bottom: 30px;
  }
  .about-us p.relax-line {
    margin-top: 110px !important;
  }
  .about-us
    .page-description-div
    + .cards-big
    + .page-description-div
    + .big-and-small-div
    + .cards-big
    + .page-description-div {
    padding: 70px 0 100px !important;
  }
  .about-us .detail-section {
    margin-top: 90px;
  }
  .about-us .page-description-div p {
    line-height: 45px;
  }
  .about-us .detail-section p {
    color: #fff;
    font-size: 13px;
  }
  .about-us .cards-big.last-new {
    position: relative;
    padding: 100px 0 150px;
  }
  .about-us .cards-big .cards.heading {
    width: 85%;
    padding-left: 70px;
  }
  .about-us .day-trips-section {
    padding: 85px 0 0;
  }
  .digital-gift-card .exclusive-newnew {
    padding-bottom: 180px;
    padding-top: 40px;
  }
  .gift-card-experience .exclusive-newnew {
    padding-top: 35px;
  }
  .gift-card-experience .exclusive-newnew.pb-80 {
    padding-bottom: 50px !important;
  }
  .tamarind-spring .tabs-section li {
    list-style: none;
    font-size: 16px;
  }
  .tamarind-spring .tabs-section {
    padding: 55px 0;
    position: relative;
  }
  .tamarind-spring .page-description-data.tamarind-spring-text p.small-text {
    color: #fff;
    font-size: 16px;
  }
  .tamarind-spring .normal-card-section {
    padding: 0 0 55px;
  }
  .tamarind-spring .card-design img {
    margin: 0 0 40px;
  }
  .tamarind-spring .day-trips-section {
    padding: 30px 0 100px;
  }
  .contact-us-page .address-div-pic svg,
  .room-type-all .card-description h4 {
    font-size: 30px;
  }
  .res-btn {
    font-size: 18px;
  }
  .room-type-all .card-description a {
    color: #fff;
    font-size: 12px;
  }
  .room-type-two .room-features ul li {
    line-height: 30px;
    font-size: 13px;
  }
  .room-type-two .exprice-slider h3 {
    font-size: 23px;
  }
  .room-type-two .exprice-slider.c-slide_image {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
  .room-type-two .exprice-slider.c-move-to-bottom.c-slide_image {
    margin-bottom: 0;
    padding-bottom: 64px !important;
  }
  .motor-bike-one .travel-motorbike h3 {
    padding: 100px 0 50px;
  }
  .motor-bike-one .travel-motorbike-tours {
    padding: 77px 0;
  }
  .motor-bike-one .vsklexprice-banner {
    padding: 0 0 93px;
  }
  .motor-bike-one .map-size {
    padding: 114px 0 100px;
  }
  .motor-bike-one .price-content-box {
    padding: 115px 0 83px;
  }
  .motor-bike-one .enquirt-section {
    padding: 0 0 111px;
  }
  .motor-bike-one .day-trips-section {
    padding: 60px 0 58px;
    background-color: #403836;
  }
  .travel-motorbike h3 {
    font-size: 40px;
    padding: 50px 0;
  }
  .travel-motorbike-tours p {
    font-size: 35px;
    line-height: 50px;
  }
  .py-170 {
    padding: 140px 0;
  }
  .list-moterbike li,
  .slider-count-text p {
    font-size: 20px;
  }
  .enquiry-form h3 {
    color: #d9ccc5;
    font-size: 35px;
    padding-bottom: 100px;
  }
  .celebration-enquiry input.rmdp-input,
  .enquiry-input {
    height: 60px;
  }
  .btn-send,
  .btn-send:hover {
    height: 60px;
    font-size: 20px;
  }
  .enquirt-section {
    padding: 0 0 190px;
  }
  .content-moterbike {
    padding: 110px 0 120px;
  }
  .tamarind-celebration.celebrate-singapore-page .banner-div h2 {
    margin-top: 100px;
    margin-bottom: 103px;
  }
  .celebration_cards_main.pb-80 {
    padding-bottom: 0 !important;
  }
  .celebration-enquiry .enquirt-section {
    padding: 100px 0 110px;
  }
  .job-apply .banner-div h2 {
    padding: 60px 0;
    font-size: 40px;
  }
  .job-apply .page-description h3 {
    margin-bottom: 70px;
  }
  .job-apply .page-description p {
    font-size: 12px;
    line-height: 20px;
  }
  .job-apply .py-200 {
    padding: 100px 0;
  }
  .slider-count-text {
    margin-top: 55px;
    font-size: 20px;
  }
  .big-font,
  .vskl-home .page-description-div h3 {
    font-size: 45px;
  }
  .property-manager-div h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .job-card-flex {
    padding: 30px;
    height: 125px;
  }
  .job-detail-page {
    margin-top: 20px;
    height: 485px;
    overflow-y: auto;
    width: 500px;
    padding: 0 40px;
  }
  .job-detail-page .close-job-detail {
    position: absolute;
    right: 25px;
    top: 20px;
  }
  .contact-us-page .banner-heading {
    padding: 30px 0;
  }
  .contact-us-page .address-div-pic .address-title {
    font-size: 21px;
    color: #f8f4f4;
    margin-bottom: 13px;
  }
  .contact-us-page .address-div-pic .address-city {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .contact-us-page ul.address-detail li {
    color: #d9ccc5;
    font-size: 13px;
  }
  .vskl-home .react-multi-carousel-item:nth-child(2n) {
    margin-top: 0;
  }
  .celebration-and-event-page .celebration_cards_main .normal-card-section {
    padding-top: 120px;
  }
  .vskl-home .day-trips-section.other-sect {
    padding: 90px 0 0;
  }
  .home-tab-section {
    position: relative;
    margin-bottom: 100px;
  }
  .card-design-big-one {
    margin-top: 75px;
  }
  .pt-200 {
    padding-top: 120px !important;
  }
  .vskl-home .day-trips-section {
    padding: 120px 0 50px;
  }
  .map-border-div {
    padding: 110px 0 25px;
  }
  .main-map-div {
    position: relative;
    height: 400px;
  }
  .sub-header .MuiSelect-select {
    font-size: 26px;
  }
  .vskl-home .page-description-div {
    padding: 100px 0 70px !important;
  }
  .vskl-home iframe {
    height: 400px;
  }
  .book-offer-div {
    position: relative;
    padding: 45px 0;
  }
  .wellness-vskl .book-offer-div {
    padding-top: 70px;
  }
  .gallery-card:nth-child(2n) {
    height: 380px !important;
  }
  .gallery-card:nth-child(odd) {
    height: 450px !important;
  }
  .room-type-all .day-trips-section {
    position: relative;
    padding: 102px 0 0;
    background-color: #403836;
  }
  .--mt-130 {
    margin-top: 55px;
  }
  .vskl-ex-offer .other-div {
    padding-top: 80px;
    padding-bottom: 120px;
  }
  .vskl-taste .banner-div h2 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .vskl-taste .day-trip-section-bordered {
    padding: 110px 0 30px;
  }
  .wellness-vskl .day-trips-section .day-trip-section-bordered {
    position: relative;
    padding: 110px 0 30px;
  }
  .wellness-vskl .btn-slider-div li {
    padding-bottom: 60px !important;
  }
  .vskl-taste .day-trips-section.restaurent-div .day-trip-section-bordered {
    padding-top: 49px;
  }
  .mt-150 {
    margin-top: 80px;
  }
  .vskl-taste .btn-slider-div li {
    padding-bottom: 60px;
  }
  .scroll-slider-div
    .react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2n) {
    transform: translateY(55px);
    bottom: 0;
    transition: 2s;
    padding-bottom: 0;
  }
  .cruise-yatch p.large-text {
    font-size: 33px;
  }
  .cruise-yatch .banner-div {
    margin: 45px 0;
  }
  .cruise-yatch .banner-div h2 {
    margin-bottom: 45px;
  }
  .cruise-yatch .birthday-party p {
    font-size: 16px;
    margin-bottom: 45px;
  }
  .cruise-yatch .banner-slider-card .slick-prev {
    bottom: -88px;
  }
  .cruise-yatch .banner-slider-card .slick-next {
    bottom: -93px;
  }
  .cruise-yatch .text-description-div {
    padding: 69px 0 120px;
  }
  .cruise-yatch .on-board-life {
    padding: 100px 0;
  }
  .cruise-yatch .on-board-life .cards.heading {
    margin-bottom: 80px;
  }
  .cruise-yatch .exclusive-card {
    padding-top: 60px;
  }
  .gallery-div-masonary {
    position: relative;
    padding: 120px 0 200px;
  }
  .experience-section-page.experience-page .page-description {
    padding: 100px 0 120px;
  }
  .experience-section-page .day-trips-section.discover-div,
  .py-200 {
    padding: 80px 0;
  }
  .navbar-main,
  .tamarind-spring
    .taste-center-card
    .normal-card-section
    .normal-card-div:first-child,
  li:first-child .bottom-cards-div,
  li:first-child .normal-card-div,
  li:first-child .slider__item,
  li:nth-child(5) .bottom-cards-div {
    padding-left: 0 !important;
  }
  .pb-200 {
    padding-bottom: 100px;
  }
  .experience-thaipusam-page.experience-section-page .experience-content-text {
    padding: 75px 0;
  }
  .digital-gift-card .purchase-div {
    margin-top: 30px;
  }
  .celebration-home.celebration-and-event-page
    .celebration_cards_main
    .normal-card-section,
  .gift-card-experience .main-bg {
    padding-bottom: 120px;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .big-font {
    color: #d9ccc5;
  }
  .celebrate-singapore-page .text-description-div p {
    margin-top: 50px;
    font-size: 16px;
    margin-bottom: 80px;
  }
  .celebrate-singapore-page .text-description-div {
    padding: 128px 0 120px;
  }
  .vskl-gallery .book-offer-div,
  .vskl-taste .book-offer-div {
    position: relative;
    padding: 45px 0 20px;
    height: 140px;
    display: flex;
    align-items: center;
  }
  .card-description {
    margin-bottom: 0;
  }
  .celebrate-singapore-home.celebrate-singapore-page .text-description-div {
    padding: 95px 0 117px;
  }
  .vskl-home .vskl-number-slide .react-multi-carousel-list {
    padding-bottom: 50px;
  }
  .vskl-ex-offer .exclusive-newnew {
    padding-top: 25px;
    padding-bottom: 80px;
  }
  .vskl-number-slide .card-design img {
    min-height: 480px;
  }
  .vskl-home
    .btn-slider-div
    .react-multi-carousel-item.react-multi-carousel-item--active
    (even),
  .vskl-home
    .number-slider.vskl-number-slide
    .btn-slider-div
    .react-multi-carousel-item.react-multi-carousel-item--active {
    transform: translateY(55px);
  }
  .vskl-home
    .btn-slider-div
    .react-multi-carousel-item.react-multi-carousel-item--active {
    position: absolute;
    bottom: 0;
    transition: 2s;
    padding-bottom: 70px;
  }
  .vskl-home
    .vskl-number-slide
    .btn-slider-div
    .react-multi-carousel-item.react-multi-carousel-item {
    transition: 1.5s;
  }
  .gallery-div-masonary::before,
  .normal-card-section .card-design img {
    width: 100%;
  }
  #keys {
    height: 600px;
  }
  .stay-home.stay-page .motor-bike-section .normal-card-section::before {
    left: 0;
    height: 2px;
    width: 100%;
  }
  .taste-home .taste-center-card::before {
    left: 57px;
    width: 91.1%;
  }
  .cruise-home.cruise-page .taste-center-card::before {
    left: 57px;
    width: 91.1%;
    height: 1px;
  }
  .exclusive-offer-page .stay-section .normal-card-section::before,
  .exclusive-offer-page .taste-section .normal-card-section::before {
    left: 0;
    height: 1px;
    width: 100%;
    bottom: 0;
  }
  .footer-main::after,
  .footer-main::before {
    left: 3%;
    content: "";
    height: 2px;
    width: 97%;
  }
  .gift-card-experience.vskl-ex-offer .exclusive-newnew::before {
    position: absolute;
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABooAAAABCAYAAADpRfw8AAAABHNCSVQICAgIfAhkiAAAAF5JREFUWEft2LsJACAQBNG9/ov2g4hwFRi8C8VIxlnXGmvSptb0NfsS55LgAAfbDTjAAQ5OSsoFPsDBuQtygQ9wIBduf+QDPuADPuCD96OmL3gn6gv6wjUCH/DB7z6Yk/GgHL8aCckAAAAASUVORK5CYII=);
    left: 0;
    height: 1px;
    width: 100%;
    bottom: 0;
  }
  .celebration-and-event-page .normal-card-div {
    margin-bottom: 30px;
  }
  .reserve-head::after {
    width: 76%;
    position: absolute;
    content: "";
    height: 2px;
    left: 25px;
    bottom: 8px;
  }
  .navbar-main {
    padding-right: 0 !important;
  }
  .vskl-ex-offer .exclusive-newnew img {
    height: 478px;
    width: 100%;
    object-fit: cover;
  }
  .vskl-ex-offer .exclusive-newnew .gift-image-section img {
    height: 335px;
    width: 100%;
    border-radius: 20px;
  }
  .tamarind-spring .content-none ul.react-multi-carousel-track.centerslider {
    height: auto;
  }
  .taste-page.tamarind-spring .btn-slider-div img {
    object-fit: cover !important;
    height: auto;
  }
}
.arrowspan {
  left: 68px;
  z-index: 9;
}
.arrowspanleft {
  left: -68px;
}
.moterbike-day ul {
  padding: 0 !important;
  color: #fff !important;
}
.moterbike-day ul li {
  list-style: none !important;
}
.mainLoader .top_loader_div {
  z-index: 9999 !important;
}
.btn-reserbox.boxed .dashed-btn {
  width: 140px !important;
  font-size: 18px !important;
  padding: 0 !important;
  height: 39px !important;
}
.taste-page.tamarind-spring .celebration-internal-div img {
  height: 481px;
  object-fit: cover !important;
}
.taste-page.taste-home .day-trips-section img {
  height: 457px !important;
}
.taste-page.tamarind-spring .btn-slider-div img {
  object-fit: cover !important;
}
.ticket_left_img img {
  margin-top: 11px;
  border-radius: 2px;
  width: 100px;
  height: 66px;
  object-fit: cover;
}
.carnival_ticket .celebration-enquiry .enquiry-form {
  padding: 75px 0 0;
}
.carnival_ticket .celebration-enquiry .enquirt-section {
  padding: 75px 0;
}
.carnival_ticket .enquiry-form h3 {
  padding-bottom: 67px;
}
.ticket_right_content {
  width: 80%;
}
.carnival_banner_left {
  padding: 270px 20px 0;
}
.carnival_banner_left img {
  width: 480px;
  height: 480px;
  object-fit: cover;
}
.order-detail-page table th {
  padding: 15px;
  border: 1px solid #dee2e6;
}
