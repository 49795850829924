@font-face {
  font-family: "RotisSerif";
  src: local("RotisSerif"),
    url("./assets/font/RotisSerif.otf") format("OpenType");
}


/* @font-face {
  font-family: "RotisSansSerif";
  src: local("RotisSansSerif"),
    url("./assets/font/RotisSansSerifLight.otf") format("OpenType");
} */

/* @font-face {
  font-family: "RotisSerif";
    src: local("RotisSerif"),
    url("./assets/font/rotis-sans-serif-bold-65.otf") format("OpenType");
} */
/* @import url(https://db.onlinewebfonts.com/c/f836c9da811eada4f862d37f796b91cd?family=RotisSerif); */
body {
  margin: 0;
  font-family: "RotisSerif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: "RotisSerif";
}
nav button, .MuiTypography-root{font-family: "RotisSerif" !important;}


